@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

:root {
  --Brand-color: #2d4bf3;
  --TurkishRose: #bc658d;
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --White: #ffffff;
  --Rhythm: #69779b;
  --CaribbeanGreen: #00c5a2;
  --OldLavender: #756c83;
  --AmericanPurple: #432160;
  --ChineseBlue: #415b90;
  --DarkSlateGray: #314a52;
  --MaximumRedPurple: #a03c78;
  --DarkLavender: #6f539b;
  --MetallicBlue: #3c5186;
  --TiffanyBlue: #0fabbc;
  --PoliceBlue: #394a6d;
  --Corn: #fbeb5b;
  --LimeGreen: #4be133;
  --OldRose: #394a6d;
  --Magnolia: #f4f3fe;
  --ChineseSilver: #cccccc;
  --LavenderGray: #c5c0db;
  --Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
  --blue: rgb(26, 57, 87);
  --white: rgb(236, 236, 236);
  --DeepGreen-CyanTurquoise: #206d62;
  --Satoshi: "Satoshi", sans-serif;
  --DM-Sans: "DM Sans", sans-serif;
  --shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  --overlay: rgba(44, 51, 58, 0.2);
}

[dark-theme="dark"] {
  --White: #2c333a;
  --Gunmetal: #ffffff;
  --White: #2c333a;
}

body {
  background-color: var(--White);
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  overflow: -moz-scrollbars-vertical;
  -webkit-tap-highlight-color: transparent;
}

.webContainer {
  max-width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
}

@media (max-width: 540px) {
  .webContainer {
    padding: 0 20px;
  }
}

.rendered {
  position: relative;
}

.rendered:before {
  content: url("../svg/estate_bLogo.svg");
  position: absolute;
}

.paymentChecks {
  margin-bottom: 20px;
}

.paymentChecks .cntr {
  margin: 10px 0;
}

.invoiceFor {
  background: url("../images/receiptBoard.png") no-repeat;
  background-size: cover;
}

.handleNavbar {
  font-family: var(--Satoshi) !important;
}

#messagesBg {
  position: relative;
}

#messagesBg:before {
  content: url("../svg/message_badge.svg");
  position: absolute;
  left: 8px;
  top: 16px;
}

#alertBg {
  position: relative;
}

#alertBg:before {
  content: url("../svg/caution.svg");
  position: absolute;
  left: 8px;
  top: 16px;
}

.notification.active {
  display: block !important;
}

#greenBgC {
  color: #00ba07;
}

#redBgC {
  color: #f3d645;
}

#greenBg {
  background: #cef3cf;
  border: 1px solid #00ba07;
  border-radius: 4px;
}

#redBg {
  background: #fffbe6;
  border: 1px solid #f3d645;
  border-radius: 4px;
}

.defaultWidth {
  width: 266px;
}

.paginationButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 452px;
  margin: 30px auto;
}

.paginationButtons .next,
.paginationButtons .previous {
  background-color: var(--Brand-color);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 5px 15px;
}

.paginationButtons .next:hover,
.paginationButtons .previous:hover {
  background: var(--Brand-color);
}

.paginationButtons li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.018em;
  padding: 5px 10px;
  margin: 3px;
  cursor: pointer;
}

.paginationButtons li:hover {
  background: #f3f3f3;
}

.paginationButtons .paginationActive {
  font-weight: 600;
  border-bottom: 2px solid var(--Brand-color);
}

.DarkLavender {
  background-color: var(--DarkLavender);
}

.DeepGreen-CyanTurquoise {
  background-color: var(--DeepGreen-CyanTurquoise);
}

.LavenderGray {
  background-color: var(--LavenderGray);
}

.DarkSlateGray {
  background-color: var(--DarkSlateGray);
}

.ChineseBlue {
  background-color: var(--ChineseBlue);
}

.ChineseSilver {
  background-color: var(--ChineseSilver);
}

.Corn {
  background-color: var(--Corn);
}

.Magnolia {
  background-color: var(--Magnolia);
}

.OldRose {
  background-color: var(--OldRose);
}

.LimeGreen {
  background-color: var(--LimeGreen);
}

.PoliceBlue {
  background-color: var(--PoliceBlue);
}

.TiffanyBlue {
  background-color: var(--TiffanyBlue);
}

.MetallicBlue {
  background-color: var(--MetallicBlue);
}

.MaximumRedPurple {
  background-color: var(--MaximumRedPurple);
}

.DarkCyan {
  background-color: var(--Brand-color);
}

.TurkishRose {
  background-color: var(--TurkishRose);
}

.Rhythm {
  background-color: var(--Rhythm);
}

.CaribbeanGreen {
  background-color: var(--CaribbeanGreen);
}

.AmericanPurple {
  background-color: var(--AmericanPurple);
}

.TurkishRose {
  background-color: var(--TurkishRose);
}

.Gunmetal {
  background-color: var(--Gunmetal);
}

.ViridianGreen {
  background-color: var(--ViridianGreen);
}

.Rhythm {
  background-color: var(--Rhythm);
}

.CaribbeanGreen {
  background-color: var(--CaribbeanGreen);
}

.OldLavender {
  background-color: var(--OldLavender);
}

.White {
  background-color: var(--White);
  color: var(--White);
}

.WhiteC {
  color: var(--White);
}

a {
  color: unset;
}

.insta_svg path {
  fill: #009ba2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--Satoshi);
  color: var(--Gunmetal);
  font-weight: 500;
  line-height: 110%;
}

h1 {
  font-size: 48px;
}

h2 {
  font-weight: 500;
  font-size: 43px;
  margin-bottom: 30px;
  line-height: 52px;
}

h3 {
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 20px;
}

h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
}

h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 20px;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.op-5 {
  opacity: 50%;
}

.max_605 {
  max-width: 605px;
}

.max_700 {
  max-width: 700px;
}

hr {
  border: 0.5px solid rgba(44, 51, 58, 0.5);
  margin: 40px 0;
}

ul li {
  font-family: var(--DM-Sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 8px 0;
  letter-spacing: 0em;
  text-align: left;
}

a,
label,
::-webkit-input-placeholder {
  font-family: var(--DM-Sans);
}

a,
label,
:-ms-input-placeholder {
  font-family: var(--DM-Sans);
}

a,
label,
::-ms-input-placeholder {
  font-family: var(--DM-Sans);
}

a,
label,
::placeholder {
  font-family: var(--DM-Sans);
}

ol {
  list-style: none;
  counter-reset: item;
}

ol li {
  counter-increment: item;
  margin-bottom: 5px;
  font-family: var(--Satoshi);
}

/* ol li:before {
  margin-right: 16px;
  margin-top: 6px;
  content: counter(item);
  background: var(--Gunmetal);
  border-radius: 100%;
  color: white;
  font-weight: 700;
  width: 25px;
  height: 25px;
  text-align: center;
  display: inline-block;
} */

.display_pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

p,
span,
li {
  font-family: var(--DM-Sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 8px 0;
  letter-spacing: 0em;
  text-align: left;
}

img {
  width: 100%;
  max-width: 100%;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.b_location::before {
  width: 24px;
  height: 24px;
  position: relative;
  top: 5px;
  margin-right: 8px;
}

.ct-series-a .ct-line {
  stroke: var(--Gunmetal) !important;
  stroke-width: 2px;
}

.ct-grids .ct-horizontal {
  stroke-width: 0px !important;
  stroke-dasharray: 5px;
}

.ct-series-a .ct-point {
  stroke-linecap: circle;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  stroke-width: 10px;
  stroke: var(--MaximumRedPurple) !important;
  cursor: pointer;
}

.ct-series-a .ct-point::before {
  content: "Hello";
  color: #009ba2;
}

.ct-series-a .ct-point:hover {
  stroke-width: 15px;
  stroke: var(--ChineseBlue) !important;
}

.b_phone::before {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.b_email::before {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.b_module::before {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-6 {
  margin-top: 60px;
}

.mt-7 {
  margin-top: 70px;
}

.mt-8 {
  margin-top: 80px;
}

.mt-9 {
  margin-top: 90px;
}

.mt-10 {
  margin-top: 100px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mb-6 {
  margin-bottom: 60px;
}

.mb-7 {
  margin-bottom: 70px;
}

.mb-8 {
  margin-bottom: 80px;
}

.mb-9 {
  margin-bottom: 90px;
}

.mb-10 {
  margin-bottom: 100px;
}

.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pt-4 {
  padding-top: 40px;
}

.pt-5 {
  padding-top: 50px;
}

.pt-6 {
  padding-top: 60px;
}

.pt-7 {
  padding-top: 70px;
}

.pt-8 {
  padding-top: 80px;
}

.pt-9 {
  padding-top: 90px;
}

.pt-10 {
  padding-top: 100px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.pb-5 {
  padding-bottom: 50px;
}

.pb-6 {
  padding-bottom: 60px;
}

.pb-7 {
  padding-bottom: 70px;
}

.pb-8 {
  padding-bottom: 80px;
}

.pb-9 {
  padding-bottom: 90px;
}

.pb-10 {
  padding-bottom: 100px;
}

button {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

button:disabled {
  opacity: 0.5;
  background-color: unset;
  color: var(--ChineseSilver);
  border: 1px solid rgba(204, 204, 204, 0.4);
}

.icon {
  width: 24px;
  height: 24px;
}

.btn_mid {
  text-align: center;
}

.handleText h5 {
  background: url("../svg/quote.svg") no-repeat;
}

.default-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: var(--ChineseSilver);
}

.important-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: var(--Brand-color);
  color: var(--White);
}

.outlined-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  background-color: var(--White);
  color: var(--Brand-color);
  border: 2px solid var(--Brand-color);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.outlined-btn:hover {
  color: var(--White);
  background-color: var(--Brand-color);
}

.primary-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: var(--Gunmetal);
  color: var(--White);
}

.danger-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: #ea5f5e;
  color: var(--White);
}

header {
  background: var(--Glass);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  width: 100%;
  z-index: 99;
  height: 80px;
  position: fixed;
  top: 0;
  -webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
    0px 2px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
}

nav {
  height: auto;
  padding: 18px 0;
  background-color: var(--White);
  position: fixed;
  top: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 12;
}

nav .navbar img {
  width: 177px;
  height: 25px;
  -o-object-fit: cover;
  object-fit: cover;
}

nav h2 {
  font-size: 30px;
  width: 177px;
  margin: 15px 0;
  text-align: left;
  font-family: var(--Satoshi);
}

.navlinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 50%;
}

.navlinks a:active {
  color: var(--Brand-color);
}

.navlinks img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.navlinks li {
  margin: 25px 0;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--DM-Sans);
  opacity: 0.9;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.navlinks li::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0%;
  height: 3px;
  background: var(--Main-Gradient);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  bottom: 0;
}

.navlinks li:hover::before {
  opacity: 1;
  width: 100%;
}

.navlinks li:hover {
  font-weight: 700;
}

.navlinks li:active {
  border-bottom: var(--Brand-color);
}

.select_drop {
  font-family: var(--DM-Sans);
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select_drop .dropdown-btn {
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  font-weight: 500;
  border: 1px solid var(--Gunmetal);
  background-color: var(--White);
  color: var(--Gunmetal);
}

.select_drop .dropdown-content {
  position: absolute;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  top: 50px;
  padding: 10px;
  background-color: var(--White);
  width: 100%;
}

.select_drop .dropdown-item {
  padding: 10px;
  cursor: pointer;
  -webkit-transform: 0.4s;
  transform: 0.4s;
}

.select_drop .dropdown-item:hover {
  background-color: var(--Magnolia);
}

.btn_only {
  --r: 5px;
  /* radius */
  --b: 2px;
  /* border width */
  background: var(--Main-Gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  border-width: 1px;
  border-style: solid;
  -o-border-image: var(--Main-Gradient);
  border-image: var(--Main-Gradient);
  margin: 15px 0;
  border-radius: var(--r);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  position: relative;
  z-index: 0;
  text-decoration: none;
  padding: 15px 30px;
  margin-right: auto;
  margin-left: auto;
}

/* check lined question for the detail of the below code */
.btn_only::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  bottom: 0;
  border-radius: var(--r);
  border-width: 1px;
  border-style: solid;
  -o-border-image: var(--Main-Gradient);
  border-image: var(--Main-Gradient);
  border-radius: var(--r);
  background: inherit;
  background-origin: border-box;
  background-clip: border-box;
  -webkit-mask: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #fff)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  -webkit-mask-repeat: no-repeat;
}

/**/
.btn_only:hover {
  color: #fff;
  -webkit-text-fill-color: #fff;
  background-clip: border-box;
}

.btn_only:hover::before {
  -webkit-mask: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.track {
  color: var(--Brand-color);
  border-bottom: 3px solid var(--Brand-color);
}

.hamburger {
  margin-top: 0px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: var(--Gunmetal);
  margin: 5px;
}

ul.breadcrumb {
  list-style: none;
  margin-bottom: 40px;
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  color: var(--Gunmetal);
}

ul.breadcrumb li+li:before {
  content: url("https://www.svgrepo.com/show/356409/arrow-right.svg");
  width: 20px;
  position: relative;
  top: 4px;
  left: -6px;
  height: 35px;
  display: block;
  padding: 0 10px;
  display: inline-block;
}

ul.breadcrumb li a {
  color: var(--ChineseSilver);
  text-decoration: none;
  -webkit-transition: 0.7s ease;
  transition: 0.7s ease;
}

ul.breadcrumb li a:hover {
  color: var(--ChineseSilver);
  text-decoration: underline;
}

#myProgress {
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  height: 8px;
  background-color: #f4f4f4;
}

#myProgress #myBar {
  width: 10%;
  border-radius: 4px;
  height: 8px;
  background-color: var(--Brand-color);
}

.border-top {
  border-top: 1px solid var(--ChineseSilver);
  margin-bottom: 40px;
}

.c_p {
  cursor: pointer;
}

.tab {
  float: left;
}

.tab__group {
  position: relative;
  height: 340px;
}

.tab__group:after {
  content: "";
  display: block;
  clear: both;
}

.tab__label {
  display: inline-block;
  padding: 0 20px;
  margin-right: 5px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  cursor: pointer;
  color: var(--Brand-color);
  border-bottom: 3px solid var(--Brand-color);
}

.tab__content {
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  right: 0;
  background: #fff;
}

.tab__radio {
  display: none;
}

.tab__radio:not(:checked)~.tab__label {
  font-size: 16px;
  font-weight: 400;
  color: var(--Gunmetal);
  background: transparent;
  border-bottom: 0;
}

.tab__radio:not(:checked)~.tab__content {
  display: none;
}

.container {
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 20px;
}

#overlay_ {
  display: none;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
}

.overlay .it_card {
  background-color: var(--White);
  padding: 20px;
  margin: 25px;
  top: 20%;
  position: absolute;
  max-width: 550px;
  width: 90%;
}

.overlay .it_card .it_top {
  height: 70px;
  background-color: var(--ChineseSilver);
  text-align: center;
  padding-top: 20px;
}

.overlay .it_card .it_top h3 {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.overlay .it_card .it_content {
  padding: 20px;
}

.overlay .it_card .bbtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.overlay .it_card .bbtn button {
  width: 25%;
  margin-right: 45px;
}

.overlay .it_card .bbtn div {
  margin-top: 40px;
  font-weight: 600;
  cursor: pointer;
  font-family: var(--DM-Sans);
}

.entry {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.06em;
  text-align: left;
  position: relative;
  color: #39e040;
}

.entry::before {
  content: url("../svg/entry_thumb.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -35px;
  top: -5px;
}

.exit {
  color: red;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.06em;
  text-align: left;
  position: relative;
}

.exit::before {
  content: url("../svg/red_exit.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -35px;
  top: -5px;
}

table {
  border-collapse: collapse;
  font-family: var(--Satoshi);
  margin: 25px 0;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

table thead tr {
  background-color: var(--LavenderGray);
  text-align: left;
  border: 1px solid rgba(44, 51, 58, 0.1);
  color: var(--Gunmetal);
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

table thead tr:nth-of-type(1) {
  background-color: #f3f3f3 !important;
}

table tbody tr {
  border: 1px solid rgba(44, 51, 58, 0.1);
  font-size: 15px;
  font-weight: 500;
}

table tbody tr:nth-of-type(even) {
  background-color: #f2f2f2 !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

table th,
table td {
  padding: 18px;
}

.pagination {
  display: inline-block;
  width: 100%;
  margin: 30px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.pagination a {
  color: black;
  float: left;
  font-weight: 600;
  padding: 8px 16px;
  margin: 0 5px;
  text-decoration: none;
}

.pagination a:hover {
  color: var(--Brand-color);
}

.testimonial_box {
  margin-top: 70px;
  margin-bottom: 40px;
}

.testimonial_box .p_reviews {
  max-width: 410px;
}

.testimonial_box .p_reviews h4 {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.testimonial_box .t_avatars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
}

.testimonial_box .t_avatars .avatars__image {
  -webkit-box-shadow: 0 0 0 4px #fff;
  box-shadow: 0 0 0 4px #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 9999px;
}

.testimonial_box .t_avatars .avatars__image p {
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
}

.testimonial_box .t_avatars .avatars__image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid var(--White);
}

.testimonial_box .t_avatars .avatars__image .lap_2 {
  position: relative;
  left: -8px;
}

.testimonial_box .t_avatars .avatars__image .lap_3 {
  position: relative;
  left: -16px;
}

.testimonial_box .t_avatars .avatars__image .lap_4 {
  position: relative;
  left: -24px;
}

.testimonial_box .t_avatars .avatars__image .lap_5 {
  position: relative;
  left: -32px;
}

.testimonial_box .t_avatars .avatars__image .lap_6 {
  position: relative;
  left: -40px;
}

.testimonial_box .t_avatars .avatars__image .lap_7 {
  position: relative;
  left: -48px;
}

.uni_results {
  z-index: 10;
  display: none;
  position: absolute;
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  background-color: var(--White);
  top: 0;
  -webkit-animation: uni_results 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: uni_results 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.uni_results ul {
  margin-bottom: 20px;
}

.uni_results ul li {
  padding: 10px;
  margin: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.uni_results ul li:hover {
  background-color: var(--Magnolia);
}

.show .uni_results {
  display: block;
}

@-webkit-keyframes uni_results {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}

@keyframes uni_results {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}

.pagination a.active {
  border-bottom: 3px solid var(--Brand-color);
  font-weight: 600;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.top_tool::before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: var(--ChineseSilver);
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 90%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.accordion {
  width: 100%;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  border: 1px solid var(--Magnolia);
  margin: 40px 0;
}

.accordion h3 {
  background-color: var(--Magnolia);
  margin: 0;
  padding: 20px;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}

.accordion input {
  display: none;
}

.acc_label,
.acc_content {
  padding: 14px 20px;
}

.acc_label input,
.acc_content input {
  display: block;
}

.acc_label {
  display: block;
  color: var(--Gunmetal);
  font-weight: 500;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  background-color: var(--White);
  border: 1px solid var(--Magnolia);
}

.acc_label::after {
  content: "+";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  font-size: 20px;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.acc_label:hover {
  background: var(--Brand-color);
  color: var(--White);
}

.acc_content {
  background-color: var(--Brand-color);
  color: white;
  font-weight: 0.85em;
  line-height: 1.6;
  display: none;
}

.acc_content p {
  color: var(--White);
}

.acc_input:checked~.acc_label {
  background-color: var(--Brand-color);
  color: var(--White);
}

.acc_input:checked~.acc_content {
  display: block;
  background-color: var(--Brand-color);
}

.acc_input:checked~.acc_label::after {
  content: "-";
  -webkit-transform: translateY(-50%) rotate(0.5turn);
  transform: translateY(-50%) rotate(0.5turn);
}

.bottom_tool::before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: var(--ChineseSilver);
  display: inline-block;
  position: absolute;
  bottom: -12px;
  left: 48%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.right_tool::before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: var(--ChineseSilver);
  display: inline-block;
  position: absolute;
  right: -12px;
  top: 48%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tools_tip {
  background-color: var(--ChineseSilver);
  padding: 16px;
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}

.tools_tip p {
  margin: 0;
}

.single_form {
  margin-bottom: 25px;
}

.single_form h1 {
  font-size: 25px !important;
}

pre {
  background-color: #f3f3f3;
  font-size: 18px;
  padding: 20px;
  max-height: 450px;
  overflow: scroll;
}

.scrollable_table {
  overflow: auto;
}

.scrollable_table p {
  margin: 0 !important;
}

@media (max-width: 1200px) {
  .scrollable_table table {
    min-width: 1200px;
  }
}

.input_parent {
  margin: 25px 0;
  height: 50px;
  font-style: var(--DM-Sans);
  position: relative;
  background-color: transparent;
}

.input_parent label {
  position: absolute;
  left: 15px;
  z-index: -2;
  top: 15px;
  -webkit-transition: top 0.4s;
  transition: top 0.4s;
}

input {
  display: block;
  width: 100%;
  height: auto;
  padding: 15px;
  font-size: 16px;
  line-height: 1.4;
  color: var(--Gunmetal);
  background-color: #fff;
  border: 1px solid #dfe3e7;
  border-radius: 5px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

input:focus {
  outline: var(--Brand-color);
}

input::-webkit-input-placeholder {
  color: #2c333a;
  opacity: 0.6;
}

input:-ms-input-placeholder {
  color: #2c333a;
  opacity: 0.6;
}

input::-ms-input-placeholder {
  color: #2c333a;
  opacity: 0.6;
}

input::placeholder {
  color: #2c333a;
  opacity: 0.6;
}

.arrowDown {
  top: 11px !important;
}

.defaultforDate {
  width: 150px;
}

.search_set {
  border: 1px solid #d8d8d8;
  padding: 0 10px;
  border-radius: 5px;

  height: 46px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search_set img {
  width: 25px;
  margin-right: 0px;
  top: 6px;
}

.search_set input {
  border: 0;
  background: transparent !important;
  outline: 0;
  height: 43px;
  font-size: 16px;
  padding: 10px;
}

.select_me {
  width: 200px;
  position: relative;
  font-family: var(--Nunito);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select_me h5 {
  margin-left: 10px;
  margin-bottom: 5px;
}

.select_me .select-btn {
  padding: 10px;
  background: var(--White);
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(44, 51, 58, 0.2);
  font-size: 16px;
  font-weight: 400;
  position: relative;
  line-height: 24px;
  letter-spacing: 0px;
  height: 45px;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--Gunmetal);
}

.select_me .select-btn span {
  position: absolute;
  left: 20px;
}

.select_me .select-btn img {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
}

.select_me .select-btn input {
  width: 90% !important;
  background: transparent;
  padding: 0;
  outline: 0;
  font-size: 16px;
  border: 0;
  margin: 0 !important;
}

.select_me .select_content {
  position: absolute;
  top: 55px;
  padding: 10px;
  z-index: 1;
  width: 100%;
  left: 0;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  overflow: auto;
}

.select_me .select_items {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 3px;
  translate: scale(1);
  transition: 0.4s;
}

.select_me .select_items:hover {
  translate: scale(1.9);
  background: #f2f6ff;
  border: 1px solid #2d4bf3;
  border-radius: 5px;
}

.radio {
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

.radio p {
  opacity: 50%;
  margin-top: 0;
  margin-left: 40px;
}

.radio input {
  display: none;
}

.radio input:disabled {
  display: none;
}

.radio input+span {
  display: inline-block;
  position: relative;
  padding-left: 40px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}

.radio input+span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--Brand-color);
}

.radio input+span::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--Brand-color);
  position: absolute;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.radio input+h3 {
  display: block;
  position: relative;
  padding-top: 23px;
  margin: 10px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 5px;
}

.radio input+h3::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--Brand-color);
}

.radio input+h3::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--Brand-color);
  position: absolute;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.radio input:checked+span::after,
.radio input:checked+h3::after {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio_disabled {
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

.radio_disabled input:disabled {
  display: none;
}

.radio_disabled input:disabled+span {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
}

.radio_disabled input:disabled+span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  background-color: #f4f3fe;
}

.radio_disabled input:disabled+span::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #c5c0db;
  position: absolute;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.check_parent .checkbox {
  display: block;
  font-family: var(--Satoshi);
  position: relative;
  padding-left: 40px;
  padding-top: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 16px;
  font-weight: 400;
  -webkit-user-select: none;
  border-radius: 5px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_parent .checkbox p {
  opacity: 50%;
}

.check_parent .checkbox input {
  position: absolute;
  width: auto;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  opacity: 0;
  border-radius: 5px;
  cursor: pointer;
}

.check_parent .checkbox input:checked~.checkmark {
  background: var(--Main-Gradient);
  border: 0;
}

.check_parent .checkbox input:checked~.checkmark:after {
  display: block;
}

.check_parent .checkbox input:disabled~.checkmark {
  background-color: var(--White);
}

.check_parent .checkbox .checkmark {
  position: absolute;
  top: 0;
  cursor: pointer;
  left: 0;
  border-radius: 5px;
  height: 25px;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  width: 25px;
  background-color: var(--White);
  border: 1px solid var(--Gunmetal);
}

.check_parent .checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mainPin,
.unitData,
textarea {
  font-family: var(--DM-Sans);
}

input:focus~label {
  top: -15px;
  color: var(--Brand-color);
  background-color: white;
  padding: 5px;
  font-size: 14px;
  z-index: 2;
}

input:focus {
  background-color: transparent;
  z-index: 2;
  border-radius: 5px;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

input:focus::-ms-input-placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}

input:valid {
  background-color: #ffffff;
}

.custom-select {
  position: relative;
  font-family: Arial;
  border: 1px solid var(--Gunmetal);
  border-radius: 5px;
  min-width: 130px;
}

.select_me {
  width: 100%;
  position: relative;
  font-family: var(--Nunito);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select_me .select-btn {
  padding: 10px;
  background: var(--White);
  border-radius: 5px;
  border: 1px solid rgba(44, 51, 58, 0.2);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: var(--Gunmetal);
}

.select_me .select-btn img {
  width: 24px;
  height: 24px;
}

.select_me .select_content {
  position: absolute;
  top: 55px;
  padding: 10px;
  width: 100%;
  left: 0;
  font-weight: 500;
  background: #ffffff;
  max-height: 200px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  overflow: auto;
}

.select_me .select_content p {
  margin: 0 !important;
}

.select_me .select_items {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-bottom: 1px solid #f5f5f5;
}

.select_me .select_items:hover {
  background-color: rgba(0, 131, 143, 0.07);
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: transparent;
  border: 1px solid rgba(44, 51, 58, 0.7);
  border-radius: 5px;
  font-family: var(--DM-Sans);
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: var(--Gunmetal) transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent var(--Gunmetal) transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: var(--Gunmetal);
  padding: 8px 16px;
  border: 1px solid var(--Gunmetal);
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: var(--Magnolia);
  top: 100%;
  left: 0;
  right: 0;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.search_parent {
  position: relative;
}

.search_parent input {
  padding: 15px 20px 15px 48px;
}

.search_parent img {
  width: 20px;
  height: 24px;
  position: absolute;
  top: 15px;
  left: 16px;
}

.password_parent {
  border-radius: 5px;
  height: 50px;
  width: 100%;
  position: relative;
  background-color: transparent;
  margin: 20px 0 10px 0;
}

.password_parent input {
  width: 100%;
  padding: 15px;
  border: 0;
  outline: 0;
  border: 1px solid var(--ChineseSilver);
  background-color: transparent;
}

.password_parent input:focus~label {
  top: -12px;
  color: var(--Brand-color);
  z-index: 2;
}

.password_parent input:focus {
  background-color: var(--ChineseSilver);
  border: 1px solid var(--Brand-color);
  z-index: 2;
}

.password_parent input:focus::-webkit-input-placeholder {
  color: transparent;
}

.password_parent input:focus:-ms-input-placeholder {
  color: transparent;
}

.password_parent input:focus::-ms-input-placeholder {
  color: transparent;
}

.password_parent input:focus::placeholder {
  color: transparent;
}

.password_parent input:valid {
  background-color: var(--White);
}

.password_parent span {
  margin: 5px 0;
  color: red;
  opacity: 0;
}

.password_parent .tribute {
  position: absolute;
  right: 30px;
  top: 13px;
}

.password_parent.active input {
  border: 1px solid red;
}

.password_parent.active input:focus~label {
  top: -12px;
  color: red;
  z-index: 2;
}

.password_parent.active span {
  opacity: 1;
}

.password_parent label {
  position: absolute;
  left: 15px;
  z-index: -2;
  top: 15px;
  -webkit-transition: top 0.4s;
  transition: top 0.4s;
}

#hide {
  display: none;
}

.form_x h3 {
  margin-bottom: 30px;
}

.form_x h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  margin: 20px 0 0 0;
  text-align: left;
}

.switch_parent .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  margin-top: 20px;
  height: 24px;
}

.switch_parent .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch_parent .switch input:checked+.slider {
  background: var(--Main-Gradient);
}

.switch_parent .switch input:focus+.slider {
  -webkit-box-shadow: 0 0 1px var(--Main-Gradient);
  box-shadow: 0 0 1px var(--Main-Gradient);
}

.switch_parent .switch input:checked+.slider:before {
  -webkit-transform: translateX(24px);
  transform: translateX(24px);
}

.switch_parent .switch input:disabled+.slider:before {
  -webkit-transform: translateX(24px);
  transform: translateX(24px);
  opacity: 50%;
}

.switch_parent .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 24px;
  border-radius: 50px;
  background: rgba(204, 204, 204, 0.4);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_parent .switch .slider:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_parent .switch .slider p {
  margin: 0 0 0 60px;
  font-weight: 400;
}

/* -----Search-filter */
.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 1.4;
  color: #5376e5;
  background-color: #fff;
  border: 1px solid #dfe3e7;
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #5376e5;
  background-color: #fff;
  border-color: #2d4bf3;
  outline: 0;
}

.card {
  -webkit-box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  background-color: #fff;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #5376e5;
  overflow: hidden;
}

.pagination-data {
  padding: 0;
  margin: 0;
}

.pagination-data li {
  list-style: none;
}

.table-filter-info {
  padding: 15px;
}

.thead-primary tr th {
  background-color: #2d4bf3;
  border-color: #2d4bf3;
  color: #fff;
}

.rc-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  margin-bottom: 50px !important;
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-total-text {
  margin-right: 12px;
  cursor: initial;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}

.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
  display: block;
  content: "...";
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
  min-width: initial;
  height: auto;
  line-height: initial;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
  padding: 6px 8px;
  height: auto;
  min-width: 32px;
  min-height: 32px;
  border: 1px solid transparent;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #656f84 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}

.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
  border-bottom: 2px solid #2d4bf3;
}

.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
  background-color: #eceff5;
  border-color: #eceff5;
}

.main_drop {
  max-width: 120px;
  font-family: var(--Montserat);
  width: 100%;
  position: relative;
}

.main_drop .main_drop_btn {
  padding: 10px 12px;
  background-color: var(--White);
  border: 1px solid var(--Magnolia);
  border-radius: 5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main_drop .main_drop_btn img {
  width: 20px;
  height: 20px;
}

.main_drop .selections {
  border-radius: 6px;
  background-color: #fff;
  position: absolute;
  top: 52px;
  z-index: 11;
  border: 1px solid var(--Magnolia);
  width: 100%;
}

.main_drop .selections .selected {
  padding: 10px 12px;
  cursor: pointer;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.main_drop .selections .selected:hover {
  background-color: var(--Magnolia);
}

@media (max-width: 768px) {
  .navlinks {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    min-width: 280px;
    position: fixed;
    background: var(--White);
    padding: 20px;
    min-height: 300px;
    top: 0;
    z-index: 90;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -webkit-transform: translateX(-768px);
    transform: translateX(-768px);
    left: 0;
  }

  .navlinks li {
    padding: 10px;
    margin: 0;
    border-bottom: 2px solid #f3f3f3;
  }

  .backgroundMask {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
  }

  .placeblur {
    display: block;
  }

  .menudrop {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  #from_mo e {
    display: none;
  }
}

.fbook {
  position: relative;
  padding-left: 30px;
}

.fbook::before {
  content: url("../svg/facebook.svg");
  position: absolute;
  left: 0;
}

.ftwiiter {
  position: relative;
  padding-left: 30px;
}

.ftwiiter::before {
  content: url("../svg/twitter.svg");
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 0;
}

.fInstagram {
  position: relative;
  padding-left: 30px;
}

.fInstagram::before {
  content: url("../svg/instagram.svg");
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 0;
}

.ghost {
  display: none;
}

@media (min-width: 767px) {
  .container {
    padding: 0 40px;
  }

  .navlinks {
    margin-right: 55px;
  }

  .wrap_moon {
    position: absolute;
    top: 5px;
    cursor: pointer;
    top: 5px;
    right: 45px;
  }

  .on_nav {
    display: none;
  }

  .hamburger {
    display: none;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0 60px;
  }
}

.usersName::before {
  content: url("../svg/estate_bLogo.svg");
  margin-right: 10px;
}

@media (min-width: 1024px) {
  .container {
    padding: 0 60px;
  }
}

.searchInput::before {
  content: "\1F50D";
  font-size: 1em;
  margin-right: 0.5em;
}

#swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.fade-in-bck {
  -webkit-animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.tracking-in-contract {
  -webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-14 13:30:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-4 13:49:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation tracking-in-contract
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-19 19:51:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in-bck
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

:root {
  --primary-color: rgba(13, 110, 139, 0.75);
  --overlay-color: rgba(24, 39, 51, 0.85);
  --menu-speed: 0.75s;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.4;
}

.container {
  max-width: 960px;
  margin: auto;
  overflow: hidden;
  padding: 0 3rem;
}

.showcase {
  background: var(--primary-color);
  color: #fff;
  height: 100vh;
  position: relative;
}

.showcase:before {
  content: "";
  background: url("https://images.pexels.com/photos/533923/pexels-photo-533923.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") no-repeat center center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.showcase .showcase-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.showcase h1 {
  font-size: 4rem;
}

.showcase p {
  font-size: 1.3rem;
}

.btn {
  display: inline-block;
  border: none;
  background: var(--primary-color);
  color: #fff;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  text-decoration: none;
}

.btn:hover {
  opacity: 0.7;
}

/* MENU STYLES */
.menu-wrap {
  position: relative;
  z-index: 1;
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menu-wrap .hamburger {
  z-index: 1;
  width: 60px;
  height: 60px;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* Hamburger Line */
.menu-wrap .hamburger>div {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
  height: 2px;
  background: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menu-wrap .hamburger>div::before,
.menu-wrap .hamburger>div::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}

/* Moves Line Down */
.menu-wrap .hamburger>div::after {
  top: 10px;
}

/* Toggler Animation */
.menu-wrap .toggler:checked+.hamburger>div {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* Turns Lines Into X */
.menu-wrap .toggler:checked+.hamburger>div:before,
.menu-wrap .toggler:checked+.hamburger>div:after {
  top: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* Rotate On Hover When Checked */
.menu-wrap .toggler:checked:hover+.hamburger>div {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* Show Menu */
.menu-wrap .toggler:checked~.menu {
  visibility: visible;
}

.menu-wrap .toggler:checked~.menu>div {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: var(--menu-speed);
  transition-duration: var(--menu-speed);
}

.menu-wrap .toggler:checked~.menu>div>div {
  opacity: 1;
  -webkit-transition: opacity 0.4s ease 0.4s;
  transition: opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.menu-wrap .menu>div {
  background: var(--overlay-color);
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.menu-wrap .menu>div>div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu>div>div>ul>li {
  list-style: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
}

.menu-wrap .menu>div>div>ul>li>a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease;
}

:root {
  --Brand-color: #2d4bf3;
  --TurkishRose: #bc658d;
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --White: #ffffff;
  --Rhythm: #69779b;
  --CaribbeanGreen: #00c5a2;
  --OldLavender: #756c83;
  --AmericanPurple: #432160;
  --ChineseBlue: #415b90;
  --DarkSlateGray: #314a52;
  --MaximumRedPurple: #a03c78;
  --DarkLavender: #6f539b;
  --MetallicBlue: #3c5186;
  --TiffanyBlue: #0fabbc;
  --PoliceBlue: #394a6d;
  --Corn: #fbeb5b;
  --LimeGreen: #4be133;
  --OldRose: #394a6d;
  --Magnolia: #f4f3fe;
  --ChineseSilver: #cccccc;
  --LavenderGray: #c5c0db;
  --Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
  --blue: rgb(26, 57, 87);
  --white: rgb(236, 236, 236);
  --DeepGreen-CyanTurquoise: #206d62;
  --Satoshi: "Satoshi", sans-serif;
  --DM-Sans: "DM Sans", sans-serif;
  --shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  --overlay: rgba(44, 51, 58, 0.2);
}

[dark-theme="dark"] {
  --White: #2c333a;
  --Gunmetal: #ffffff;
  --White: #2c333a;
}

body {
  background-color: var(--White);
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  -webkit-tap-highlight-color: transparent;
}

header {
  background: var(--Glass);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  width: 100%;
  z-index: 999;
  height: 80px;
  background-color: var();
  position: fixed;
  top: 0;
  -webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
    0px 2px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
}

nav {
  height: 80px;
  padding: 18px 0;
  position: fixed;
  top: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 12;
}

nav .logo img {
  width: 177px;
  height: 25px;
  -o-object-fit: cover;
  object-fit: cover;
}

nav h2 {
  font-size: 30px;
  width: 177px;
  text-align: left;
  font-family: var(--Nunito);
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

@media (min-width: 768px) {
  .navlinks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    width: 100%;
    margin-right: 0;
  }

  .navlinks a:active {
    color: rgba(0, 0, 0, 0.05);
  }

  .navlinks #mobile {
    display: none;
  }

  .navlinks .menu-items button {
    display: block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    display: block;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 10px !important;
  }

  .navlinks img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .navlinks a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .navlinks li {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--DM-Sans);
    opacity: 0.9;
    position: relative;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin-right: 10px;
  }

  .navlinks li::before {
    content: "";
    position: absolute;
    left: 0;
    width: 0%;
    height: 3px;
    background: var(--Main-Gradient);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    bottom: 0;
  }

  .navlinks li:hover::before {
    opacity: 1;
    width: 100%;
  }

  .navlinks li:hover {
    font-weight: 700;
  }

  .navlinks li:active {
    border-bottom: #00838f;
  }

  .sign_up {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .sign_up button {
    padding: 8px 15px;
    margin: 0;
  }

  .sign_up .outlined-btn {
    margin-right: 10px;
  }

  button span {
    margin-left: 3px;
  }

  .menu-items>a,
  .menu-items button {
    text-align: left;
    padding: 10px 15px;
  }

  .arrow::after {
    content: "";
    display: none;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .dropdown {
    position: absolute;
    right: 0;
    top: 48px;
    left: auto;
    -webkit-box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }

  .dropdown li:hover {
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .dropdown li::after {
    display: none;
  }

  .dropdown.show {
    display: block;
  }

  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  /* content */
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 20px;
  }

  .content h2 {
    margin-bottom: 1rem;
  }

  .content a {
    color: #cc3852;
    margin-right: 10px;
  }
}

@media (max-width: 740px) {
  .navlinks .sign_up button {
    width: 100%;
    margin-top: 5px;
  }

  .navlinks .sign_up .outlined-btn {
    margin-top: 20px;
  }

  .navlinks .dropdown li {
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .navlinks {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: initial;
    -ms-flex-pack: initial;
    justify-content: initial;
    min-width: 280px;
    position: fixed;
    background: var(--White);
    padding: 20px;
    min-height: 150px;
    top: 0;
    z-index: 90;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -webkit-transform: translateX(-768px);
    transform: translateX(-768px);
    left: 0;
  }

  .navlinks li {
    padding: 10px 0;
    margin: 0;
    border-bottom: 2px solid #f3f3f3;
  }

  .navlinks li button {
    margin: 0;
    padding: 0;
  }

  #screen {
    display: none;
  }

  .backgroundMask {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .placeblur {
    display: block;
  }

  .menudrop {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  #from_mobile {
    display: none;
  }
}

:root {
  --Brand-color: #2d4bf3;
  --TurkishRose: #bc658d;
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --White: #ffffff;
  --Rhythm: #69779b;
  --CaribbeanGreen: #00c5a2;
  --OldLavender: #756c83;
  --AmericanPurple: #432160;
  --ChineseBlue: #415b90;
  --DarkSlateGray: #314a52;
  --MaximumRedPurple: #a03c78;
  --DarkLavender: #6f539b;
  --MetallicBlue: #3c5186;
  --TiffanyBlue: #0fabbc;
  --PoliceBlue: #394a6d;
  --Corn: #fbeb5b;
  --LimeGreen: #4be133;
  --OldRose: #394a6d;
  --Magnolia: #f4f3fe;
  --ChineseSilver: #cccccc;
  --LavenderGray: #c5c0db;
  --Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
  --blue: rgb(26, 57, 87);
  --white: rgb(236, 236, 236);
  --DeepGreen-CyanTurquoise: #206d62;
  --Satoshi: "Satoshi", sans-serif;
  --DM-Sans: "DM Sans", sans-serif;
  --shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  --overlay: rgba(44, 51, 58, 0.2);
}

[dark-theme="dark"] {
  --White: #2c333a;
  --Gunmetal: #ffffff;
  --White: #2c333a;
}

body {
  background-color: var(--White);
}

:root {
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --Magnolia: #f4f3fe;
  --Gray: #f4f4f4;
  --White: #ffffff;
  --Nunito: "Nunito", sans-serif;
  --DM-Sans: "Montserrat", sans-serif;
}

#file-bulletlist-md-readme,
.gist-meta,
.gist-file {
  display: none;
}

footer {
  -webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
    0px 2px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  height: auto;
  background-color: var(--White);
  z-index: 10;
  margin-top: 100px;
  position: relative;
}

footer .InstinctHub_foot {
  width: 215px;
}

footer .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 255px;
  width: 100%;
  margin: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

footer .media img {
  width: 40px;
  height: 30px;
  -o-object-fit: cover;
  object-fit: cover;
}

footer .location {
  padding-left: 27px;
}

footer .location::before {
  content: url("../svg/location.svg");
  position: absolute;
  left: -5px;
}

footer .phone {
  padding-left: 27px;
}

footer .phone::before {
  content: url("../svg/phone.svg");
  position: absolute;
  left: -5px;
}

footer .email {
  padding-left: 27px;
}

footer .email::before {
  content: url("../svg/email.svg");
  position: absolute;
  left: -5px;
}

footer .reserved_foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}

footer .reserved_foot .main {
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

footer .reserved_foot .main h5 {
  margin-right: 20px;
}

footer .reserved_foot .main ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

footer .reserved_foot .main ul li {
  margin-top: 0;
  padding: 0 10px;
}

footer .reserved_foot .main ul li::before {
  content: "";
  width: 5px;
  display: inline-block;
  background-color: rgba(44, 51, 58, 0.2);
}

footer .reserved {
  padding: 15px 0;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 0;
  background-color: var(--Gray);
  text-align: center;
}

footer .wrap_footer_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 60%;
}

footer .wrap_footer_icon img {
  width: 35px;
}

footer .quick_links {
  padding-top: 30px;
}

footer .quick_links h3 {
  margin-bottom: 20px;
  font-weight: 600;
}

footer .quick_links li {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 10px;
}

footer .quick_links li a {
  font-family: var(--Nunito);
}

footer .quick_links li:hover {
  color: var(--ViridianGreen);
  font-weight: 600;
}

footer .social_media {
  text-align: center;
}

footer .social_media .media {
  margin: 30px auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 16%;
}

footer .social_media .media img {
  width: 24px;
  height: 24px;
}

footer .copyright {
  text-align: center;
  padding: 15px 0;
  background-color: var(--Gunmetal);
}

footer .copyright h5 {
  margin: 0;
  color: var(--White);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
}

footer ul.breadcrumb {
  list-style: none;
  margin: 20px 0 40px 0;
  font-family: var(--Nunito);
}

footer ul.breadcrumb li {
  display: inline;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--DarkCyan);
}

footer ul.breadcrumb li+li:before {
  content: "";
  border: 1px solid var(--Gunmetal);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: var(--Gunmetal);
  display: inline-block;
  width: 0px;
  position: relative;
  top: -4px;
}

footer ul.breadcrumb li a {
  color: var(--Gunmetal);
  text-decoration: none;
  -webkit-transition: 0.7s ease;
  transition: 0.7s ease;
}

footer ul.breadcrumb li a:hover {
  color: var(--Gunmetal);
  text-decoration: underline;
}

@media (min-width: 580px) {
  .footer_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .footer_wrap .quick_links {
    padding-bottom: 40px;
    width: 44%;
  }
}

@media (max-width: 540px) {
  footer .reserved_foot .main {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  footer .media {
    max-width: 250px;
    margin: 20px auto;
  }
}

@media (min-width: 980px) {
  .footer_wrap .quick_links {
    width: 12%;
  }

  .it_logo {
    width: 23% !important;
  }

  .last-media {
    width: 23% !important;
  }
}

:root {
  --Brand-color: #2d4bf3;
  --TurkishRose: #bc658d;
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --White: #ffffff;
  --Rhythm: #69779b;
  --LightBlack: #354858;
  --CaribbeanGreen: #00c5a2;
  --OldLavender: #756c83;
  --AmericanPurple: #432160;
  --ChineseBlue: #415b90;
  --DarkSlateGray: #314a52;
  --MaximumRedPurple: #a03c78;
  --DarkLavender: #6f539b;
  --MetallicBlue: #3c5186;
  --TiffanyBlue: #0fabbc;
  --PoliceBlue: #394a6d;
  --Corn: #fbeb5b;
  --LimeGreen: #4be133;
  --OldRose: #394a6d;
  --Magnolia: #f4f3fe;
  --ChineseSilver: #cccccc;
  --LavenderGray: #c5c0db;
  --Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
  --blue: rgb(26, 57, 87);
  --white: rgb(236, 236, 236);
  --DeepGreen-CyanTurquoise: #206d62;
  --Satoshi: "Satoshi", sans-serif;
  --DM-Sans: "DM Sans", sans-serif;
  --shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  --overlay: rgba(44, 51, 58, 0.2);
}

.ff-right h1 {
  font-size: 28px;
  font-weight: 700;
}

.ff-right p {
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: var(--Brand-color);
  text-align: left;
}

.ff-right p::before {
  content: "";
  width: 7px;
  margin-right: 5px;
  height: 7px;
  display: inline-block;
  background-color: var(--Brand-color);
  border-radius: 50%;
}

.dashboard_container {
  padding: 0 30px;
}

.changeNarative {
  font-family: var(--Satoshi);
  position: relative;
}

.changeNarative::after {
  content: "Super Admin";
  display: inline-block;
  font-weight: 700;
  color: var(--Brand-color);
  font-size: 14px;
  background: #ffffff;
  line-height: 20px;
  padding: 7px 12px;
  border-radius: 5px;
  position: absolute;
  left: 35px;
  top: 45px;
}

.changeNarative img {
  margin-bottom: 80px !important;
}

.changeAdmin {
  font-family: var(--Satoshi);
  position: relative;
}

.changeAdmin::after {
  content: "Admin";
  display: inline-block;
  font-weight: 700;
  color: var(--Brand-color);
  font-size: 14px;
  background: #ffffff;
  line-height: 20px;
  padding: 3px 12px;
  border-radius: 5px;
  position: absolute;
  left: 50px;
  top: 40px;
}

.changeResident {
  font-family: var(--Satoshi);
  position: relative;
}

.changeResident::after {
  content: "Residents";
  display: inline-block;
  font-weight: 700;
  color: var(--Brand-color);
  font-size: 14px;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  left: 50px;
  top: 40px;
}

.changeSecurity {
  font-family: var(--Satoshi);
  position: relative;
}

.changeSecurity::after {
  content: "Security";
  display: inline-block;
  font-weight: 700;
  color: var(--Brand-color);
  font-size: 14px;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  left: 50px;
  top: 40px;
}

.capture_close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.capture_close img {
  width: 25px;
  height: 25px;
}

.capture_close h1 {
  margin-bottom: 30px;
}

.acct_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}

.acct_info .reset-password {
  text-align: right;
}

.acct_info .reset-password a {
  text-align: right;
}

.acct_info .or_line {
  text-align: center;
}

.acct_info .or_line p {
  text-align: center;
  padding: 10px;
}

.acct_info .or_line p:before {
  content: "";
  display: inline-block;
  width: 48%;
  width: 100px;
  top: -4px;
  height: 1px;
  background: rgba(51, 51, 51, 0.15);
  position: relative;
  left: -10px;
}

.acct_info .or_line p:after {
  content: "";
  display: inline-block;
  top: -4px;
  width: 48%;
  width: 100px;
  height: 1px;
  right: -10px;
  position: relative;
  background: rgba(51, 51, 51, 0.15);
}

.acct_info .create_bg {
  width: 45%;
  background: rgba(0, 0, 0, 0.1);
  min-height: 100vh;
}

.acct_info .create_bg .create_est {
  max-width: 470px;
  margin: 20px auto;
  margin-top: 32px;
  padding: 20px;
}

.acct_info .create_bg .create_est button {
  width: 100%;
  background: var(--Brand-color);
}

.acct_info .create_bg .create_est form {
  padding: 30px;
  background: var(--White);
}

.acct_info .create_bg .create_est form .select_me {
  margin-bottom: 15px !important;
}

.acct_info .create_bg .create_est form .form_txt h3 {
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 20px;
}

.acct_info .create_bg .create_est form .form_txt p {
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.acct_info .create_bg .create_est form .form_txt .with_link {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 15px;
  text-align: left;
}

.acct_info .create_bg .create_est form .form_txt .with_link a {
  color: var(--Brand-color);
  margin-left: 8px;
}

.acct_info .create_bg .create_est form .form_txt h1 {
  font-size: 25px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  margin-bottom: 20px;
}

.acct_info .create_bg .create_est form .form_txt h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 15px;
}

.acct_info .create_bg .create_est form input {
  border: 0.89362px solid rgba(44, 51, 58, 0.5);
  border-radius: 4.46809px;
  margin-bottom: 15px;
}

.acct_info .create_bg .create_est form button {
  width: 100%;
}

.acct_info .create_bg img {
  width: 190px;
  height: 50.99px;
  margin-bottom: 35px;
}

.acct_info .default_p h1 {
  margin-bottom: 10px;
}

.acct_info .default_p p {
  font-size: 16px !important;
  line-height: 28px !important;
  margin-bottom: 15px;
}

.acct_info .create_img {
  width: 55%;
}

.acct_info .create_img img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.double_input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.double_input .dropdown-container {
  width: 95%;
}

.double_input .dropdown-container input {
  width: 100%;
}

.label-cbx {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
}

.label-cbx input:checked+.checkbox {
  border-color: var(--Brand-color);
}

.label-cbx input:checked+.checkbox svg path {
  fill: var(--Brand-color);
}

.label-cbx input:checked+.checkbox svg polyline {
  stroke-dashoffset: 0;
}

.label-cbx:hover .checkbox svg path {
  stroke-dashoffset: 0;
}

.label-cbx .checkbox {
  position: relative;
  top: 2px;
  float: left;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border: 2px solid #c8ccd4;
  border-radius: 3px;
}

.label-cbx .checkbox svg {
  position: absolute;
  top: -2px;
  left: -2px;
}

.label-cbx .checkbox svg path {
  fill: none;
  stroke: var(--Brand-color);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 71px;
  stroke-dashoffset: 71px;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.label-cbx .checkbox svg polyline {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.label-cbx>span {
  pointer-events: none;
  vertical-align: middle;
}

.svg_control .gratitude {
  margin-bottom: 30px;
}

.svg_control .gratitude label {
  border: 1px solid rgba(242, 246, 255, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fbfbfb;
  border-radius: 17.9149px;
  height: 100%;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.svg_control .gratitude label:hover,
.svg_control .gratitude label:focus,
.svg_control .gratitude label:active {
  background: #f2f6ff;
  border: 1px solid #2d4bf3;
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
}

.svg_control .gratitude:hover svg {
  width: 45px;
  height: 45px;
  margin-bottom: 15px;
}

.svg_control .gratitude:hover svg rect {
  fill: #f2f6ff;
}

.svg_control .gratitude:hover svg path {
  fill: #2d4bf3;
}

.svg_control .gratitude .choose_txt p {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.svg_control .gratitude svg {
  width: 45px;
  height: 45px;
  margin-bottom: 15px;
}

.svg_control .gratitude svg rect {
  fill: #f2f6ff;
}

.svg_control .gratitude svg path {
  fill: #2d4bf3;
}

.svg_control .gratitude input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.svg_control .gratitude input[type="radio"]:active~label {
  opacity: 1;
}

.svg_control .gratitude input[type="radio"]:checked~label {
  background: #f2f6ff;
  opacity: 1;
  border: 0.895745px solid #2d4bf3;
}

.svg_control .gratitude input[type="radio"]:checked~label svg rect {
  fill: #92b0fc;
}

.svg_control .gratitude input[type="radio"]:checked~label svg path {
  fill: #ffffff;
}

.invisible {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
}

.input_avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px 30px;
}

.input_avatar input {
  width: 270px;
}

.input_avatar h6 {
  opacity: 0.1;
}

.search_profile {
  margin: 25px 20px;
}

.search_profile .violet {
  margin-bottom: 0px;
}

.violet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.violet .note_bell {
  width: 40px;
  cursor: pointer;
  height: 40px;
  margin-top: 7px;
  margin-right: 15px;
}

.violet .user_mode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.violet .user_mode>div {
  margin-left: 20px;
}

.violet .user_mode h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 21px;
  letter-spacing: -0.06em;
  text-align: left;
  margin-right: 15px;
}

.violet .user_mode p {
  margin: 0;
  background: rgba(234, 203, 48, 0.1);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  margin-top: 7px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--Brand-color);
}

.violet .user_mode img {
  width: 48px !important;
  height: 48px !important;
  margin-top: 8px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  cursor: pointer;
}

.violet .user_mode img:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.google_facebook {
  margin-top: 15px;
}

.google_facebook a {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.google_facebook .google_f {
  margin-bottom: 15px;
  height: 49.15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0.89362px solid rgba(44, 51, 58, 0.8);
  border-radius: 4.46809px;
  text-align: center;
  padding: 5px;
}

.google_facebook .google_f::before {
  position: relative;
  left: -10px;
  top: 5px;
}

.google_facebook .google:before {
  content: url("../svg/google.svg");
}

.google_facebook .facebook:before {
  content: url("../svg/facebook_b.svg");
}

.navbar_container {
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  background: var(--White);
  position: fixed;
  top: 0;
  z-index: 999;
  width: 82%;
  right: 0;
}

.download_filter h6 {
  opacity: 0;
  visibility: hidden;
}

.with_blue {
  border: 0;
  margin-top: 0px;
}

.with_blue .select-btn {
  background: var(--Brand-color);
  border: 0;
  color: var(--White);
}

.with_blue input {
  margin: 0 !important;
}

.location_before {
  position: relative;
}

.location_before p {
  color: var(--Gunmetal);
  margin-left: 20px;
  height: 83px;
  overflow: hidden;
}

.location_before p::before {
  content: url("../svg/estate_location.svg");
  position: absolute;
  left: 0px;
  width: 35px;
  height: 35px;
}

.latest_estate {
  background: #ffffff;
  border: 2px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 50px;
}

.latest_estate .ff_heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 20px;
}

.latest_estate .ff_heading h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.latest_estate .ff_heading a {
  font-size: 16px;
  font-weight: 500;
  color: var(--Brand-color);
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
}

.latest_estate .new_estates {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.latest_estate .new_estates .ff_part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  position: relative;
}

.latest_estate .new_estates .ff_part img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 20px;
}

.latest_estate .new_estates .ff_part h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
  text-align: left;
}

.latest_estate .new_estates .ff_part p {
  height: 56px;
  overflow: hidden;
}

.latest_estate .new_estates .ff_part2 {
  width: 40%;
  text-align: right;
}

.latest_estate .new_estates .ff_part2 button {
  margin-top: 0;
  padding: 4px 7px;
  background: #f6f6f6;
  border: 1px solid #999999;
  border-radius: 5px;
  color: var(--Gunmetal);
  font-weight: 500;
  cursor: auto;
  text-align: right;
}

.latest_estate .new_estates .ff_part2 h4 {
  color: #545454;
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  line-height: 13px;
}

.latest_estate .new_estates .ff_part2 p {
  font-size: 14px;
  text-align: right;
}

.estates_tab .tabs button {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 5px 10px !important;
  border-radius: 0;
  padding-top: 7px;
  margin: 0;
  border-bottom: 5px solid var(--Magnolia);
  margin-right: 15px;
}

.estates_tab .tabs .tab.active {
  font-weight: 500;
  color: var(--Brand-color);
  border-bottom: 4px solid var(--Brand-color);
}

/*=======
OVERVIEW 
=========*/
.control_view {
  min-height: 100vh;
  position: fixed;
  left: 0;
  background-color: var(--White);
}

.control_view .desktop_nav {
  padding: 35px 20px;
  background: var(--Brand-color);
  position: relative;
  height: 100vh;
}

.control_view .desktop_nav .loging_out {
  position: absolute;
  bottom: 5px;
  width: 82%;
  border-top: 1px solid #f6f6f6;
}

.control_view .desktop_nav h4 {
  font-size: 16px;
  margin-top: 6px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.control_view .desktop_nav img {
  height: 37px;
  width: 138.75px;
  left: 26.25px;
  top: 24px;
  margin-bottom: 50px;
  border-radius: 0px;
}

#option div {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  border-radius: 10px;
}

#option h4 {
  color: var(--White);
}

#option svg {
  width: 22px;
  height: 22px;
  margin-top: 5px;
  margin-right: 13px;
}

#option svg path {
  fill: var(--White);
}

.active_tab div {
  border: 2px solid #f5de62;
  background: var(--White);
}

.active_tab h4 {
  color: var(--Gunmetal) !important;
}

.active_tab svg path {
  fill: var(--Brand-color) !important;
}

.change_ratio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.change_ratio .control_view {
  width: 18%;
}

.change_ratio .selected_tab {
  position: absolute;
  width: 82%;
  margin-top: 120px;
  right: 0;
}

.estate_cards .per_estate {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 25px;
  padding: 16px;
}

.estate_cards .per_estate button {
  background: #f2f6ff;
  padding: 4px 8px;
  font-weight: 500;
  border: 1px solid #5376e5;
  border-radius: 5px;
  margin-top: 0;
  cursor: auto;
}

.estate_cards .per_estate button:hover {
  color: var(--Brand-color);
}

.estate_cards .per_estate .name_id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.estate_cards .per_estate .name_id h2 {
  margin-right: 12px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.estate_cards .per_estate .name_id h2::after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  background-color: var(--Gunmetal);
}

.estate_cards .per_estate .name_id p {
  font-weight: 500;
  margin-top: 3px;
  font-size: 14px;
  line-height: 16px;
  color: var(--Brand-color);
}

.estate_cards .per_estate h3 {
  margin-top: 15px;
  font-weight: 700;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 16px;
}

.estate_cards .per_estate p {
  margin-top: 0;
}

.adding_new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.adding_new .important-btn {
  margin: 0;
  padding: 10px 14px;
  height: auto;
}

.bills_on_me {
  position: fixed;
  top: 70px;
  right: 0;
  width: 82%;
  height: 100%;
  overflow: scroll;
  z-index: 60;
  padding: 0 20px;
  padding-bottom: 30px;
  background: rgba(0, 0, 0, 0.3);
}

.control_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.control_btn .outlined-btn {
  border: 1px solid var(--Brand-color);
}

.modalContainer {
  max-width: 470px;
  margin: 30px auto;
  position: relative;
}

.modalContainer img {
  width: 35px;
  top: 30px;
  position: absolute;
  right: 30px;
  cursor: pointer;
  padding: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.modalContainer img:hover {
  background: #f3f3f3;
}

.form-group button {
  width: 100%;
  color: #ffffff;
  background: #2d4bf3;
}

.new_estates_form {
  background-color: var(--White);
  max-width: 470px;
  margin: 50px auto;
  padding: 30px;
}

.new_estates_form input {
  margin-bottom: 15px;
}

.new_estates_form button {
  width: 100%;
  background-color: var(--Brand-color);
  color: var(--White);
}

.new_estates_form .create_est .select_me {
  margin-bottom: 15px;
}

.new_estates_form .create_est h3 {
  font-family: var(--Satoshi) !important;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.new_estates_form .create_est .h3head {
  padding: 0 !important;
  margin-bottom: 30px;
}

.new_estates_form .create_est button {
  text-align: right;
  background-color: var(--Brand-color);
}

.new_estates_form .create_est h1 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
}

.new_estates_form .create_est h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 20px;
}

.success_message {
  background: #ffffff;
  padding: 20px;
  border: 1px solid #6eec73;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.success_message p {
  font-weight: 700;
  font-size: 16px;
  line-height: 115%;
  margin: 0;
}

.success_message button {
  margin: 0;
  height: auto;
  padding: 6px 25px;
  margin-left: 30px;
  height: 40px;
}

.success_message span {
  font-weight: 400;
}

.breadcrumb_control .breadcrumb {
  background: none;
  padding: 0;
}

.breadcrumb_control .breadcrumb .current_bread {
  margin-left: 10px;
}

.group_profile .c_del {
  width: 50%;
}

.group_profile .Cap_estate_name .contact_info {
  margin-top: 50px;
}

.group_profile .Cap_estate_name .contact_info h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 15px;
  color: var(--Brand-color);
}

.group_profile .Cap_estate_name .contact_info h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #545454;
  margin-bottom: 10px;
}

.group_profile .Cap_estate_name .contact_info p {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.group_profile .Cap_estate_name .resident_data {
  margin-top: 50px;
}

.group_profile .Cap_estate_name .resident_data .per {
  margin-bottom: 30px;
}

.group_profile .Cap_estate_name .resident_data .per h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 15px;
  color: var(--Brand-color);
}

.group_profile .Cap_estate_name .resident_data .per .active:before {
  background: var(--LimeGreen);
}

.group_profile .Cap_estate_name .resident_data .per .in-active:before {
  background: #ff0000;
}

.group_profile .Cap_estate_name .resident_data .per h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #545454;
  margin-bottom: 10px;
}

.group_profile .Cap_estate_name .resident_data .per h3:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}

.group_profile .Cap_estate_name .resident_data .per p {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.group_profile .Cap_estate_name .group_profile_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.group_profile .Cap_estate_name .group_profile_header img {
  width: 48px;
  height: 48px;
  border-radius: 5px;
  margin-right: 20px;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name h1 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .estate_id {
  margin-top: 0;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .estate_id::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background: var(--Gunmetal);
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .activity_log {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 200px;
  margin-top: 15px;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .activity_log a {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--Brand-color);
  margin-top: 0;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .activity_log a:hover {
  text-decoration: underline;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .location_before {
  max-width: 535px;
  height: auto !important;
  margin-top: 15px;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .packages_name {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .packages_name button {
  background: #d6deff;
  border-radius: 5px;
  padding: 4px 8px;
  margin: 0;
  margin-right: 15px;
  font-weight: 500;
}

.group_profile .Cap_estate_name .group_profile_header .estate_name .packages_name h4 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ff0000;
}

.group_profile .admins_list {
  background: var(--White);
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  margin-top: 40px;
  padding: 15px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.group_profile .admins_list h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: -0.06em;
  margin-bottom: 15px;
  color: var(--Brand-color);
}

.group_profile .admins_list .per_admin {
  background: var(--White);
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 10px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}

.group_profile .admins_list .per_admin .name_pic {
  width: 72%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.group_profile .admins_list .per_admin .name_pic p {
  margin: 0;
  line-height: 11px !important;
}

.group_profile .admins_list .per_admin .name_pic .ad_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.group_profile .admins_list .per_admin .name_pic .ad_name h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.group_profile .admins_list .per_admin .name_pic .ad_name p {
  margin-top: 7px !important;
  font-weight: 500;
  font-size: 14px;
  color: var(--Brand-color);
}

.group_profile .admins_list .per_admin .name_pic img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
}

.perTask {
  background: #fffbe6;
  border-radius: 5px;
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.perTask span,
.perTask h4,
.perTask h5,
.perTask p {
  margin: 0;
}

.perTask .main_drop {
  font-family: var(--DM-Sans);
}

.perTask .caution_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.perTask .caution_name img {
  width: 40px;
  margin-right: 15px;
  height: 40px;
}

.perTask .caution_name .c_tag span {
  font-weight: 400;
  font-size: 14px;
  padding: 5px 10px;
  background: #f2f6ff;
  border: 1px solid #f2f6ff;
  border-radius: 5px;
  line-height: 16px;
  margin-bottom: 10px !important;
  color: #5376e5;
}

.perTask .caution_name .c_tag p {
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
  margin-top: 10px !important;
}

.perTask .caution_name .c_tag p span {
  background: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  border: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #545454;
  position: relative;
  top: 7px;
}

.perTask .caution_name .c_tag p span:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #00171f;
}

.perTask .caution_name .c_tag h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-top: 10px;
  text-transform: uppercase;
  color: #ef9c50;
}

.perTask .caution_name .c_tag h5 {
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  color: #545454;
}

.call_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 85px;
  width: 30%;
}

.call_text img {
  width: 35px;
  height: 35px;
}

.estateid {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--Brand-color) !important;
}

.estateid:before {
  content: "";
  width: 5px;
  display: inline-block;
  position: relative;
  top: -3px;
  margin-right: 7px;
  height: 5px;
  border-radius: 50%;
  background: var(--Gunmetal);
}

.swal2-container.swal2-backdrop-show {
  padding: 0 25px;
}

.swal2-styled.swal2-confirm {
  background: var(--Brand-color) !important;
  max-width: 150px !important;
}

.control_dialog h1 {
  color: var(--Brand-color);
  font-size: 34px !important;
}

.swal2-title {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 30px !important;
  display: inline-block;
}

.swal2-title h1 {
  font-size: 40px;
}

.swal2-title h4 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.all_overview {
  margin-top: 30px;
  margin-bottom: 20px;
}

.all_overview .per_overview {
  background-color: yellow;
  border: 2px solid #e1e1e1;
  border-radius: 5px;
  background-color: var(--White);
  padding: 15px;
  margin: 15px;
}

.all_overview .per_overview>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 25px;
}

.all_overview .per_overview h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  margin: 0;
}

.all_overview .per_overview p {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  letter-spacing: 0em;
}

.all_overview .per_overview img {
  width: 40px;
  height: 40px;
}

.creating_task {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.creating_task .select_me {
  margin-top: 10px;
  width: 180px;
}

.before_list {
  padding-left: 40px;
}

.before_list:before {
  content: "";
  width: 30px;
  height: 30px;
  top: 5px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
}

.over_view:before {
  content: url("../svg/overview.svg");
}

.est:before {
  content: url("../svg/est.svg");
}

.msg:before {
  content: url("../svg/msg.svg");
}

.tsk:before {
  content: url("../svg/tsk.svg");
}

.psm:before {
  content: url("../svg/psm.svg");
}

.comp:before {
  content: url("../svg/comp.svg");
}

.log_out:before {
  content: url("../svg/logoot.svg");
}

.loging_out {
  position: absolute;
  bottom: 0;
}

.loging_out li {
  width: 100%;
  border-bottom: 0 !important;
  color: red !important;
  border-top: 1px solid var(--Magnolia) !important;
}

.subscription {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.subscription .ff_side h1 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.subscription .ff_side p {
  color: #5376e5;
}

.each_package {
  padding: 20px;
  border: 2px solid #c0c0c0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
}

.each_package img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.each_package .package_name h3 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 24px;
}

.each_package .package_name p {
  margin: 0;
}

.each_package .package_name h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  margin-top: 5px;
}

.each_package .package_name .inline {
  margin-top: 30px;
}

.each_package .package_name .inline .edit {
  margin-right: 10px;
  color: var(--Brand-color);
}

.each_package .package_name .inline .delete {
  margin-right: 10px;
  color: red;
}

.form_frame .renovated {
  /* background: rgba(0, 0, 0, 0.1); */
}

.form_frame .renovated img {
  width: 190px;
}

.form_frame .renovated .plus_img {
  margin: 0 auto;
  padding: 50px 20px;
  max-width: 460px;
}

.form_frame .renovated .plus_img img {
  margin-bottom: 30px;
}

.form_frame .renovated form {
  padding: 30px;
  border-radius: 5px;
  background: var(--White);
}

.control_package {
  margin-top: 30px;
  margin-bottom: 50px;
}

.switch-title {
  margin-top: 30px !important;
}

.switch-title h5 {
  font-weight: 500;
  margin-bottom: 6px;
}

.packageNmae {
  margin-bottom: 30px;
}

.switch-field input {
  clip: rect(0, 0, 0, 0);
  margin-top: 0 !important;
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.custom_search {
  position: relative;
  font-family: var(--DM-Sans);
  height: 65px;
}

.custom_search input {
  position: absolute;
  top: 0;
  z-index: 10;
}

.custom_search .search_result {
  width: 100%;
  position: absolute;
  top: 40px;
  padding-top: 8px;
  z-index: 5;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  max-height: 300px;
  overflow: auto;
}

.custom_search .search_result .drop_items {
  padding: 15px;
  cursor: pointer;
  border-bottom: 0.5px solid var(--Magnolia);
}

.custom_search .search_result .drop_items:hover {
  background: var(--Magnolia);
}

.measure .input_container {
  width: 100%;
  min-width: 0;
  max-width: 430px;
  position: relative;
}

.measure .input_container::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: solid 2px #979797;
  pointer-events: none;
}

.measure .input_container::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: -webkit-transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: solid 2px #ff407f;
  pointer-events: none;
}

.measure .input_container:focus-within::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.measure .input {
  width: 100%;
  padding-bottom: 12px;
  min-width: 0;
  max-width: 430px;
  border: none;
  outline: none;
  height: 40px;
  font-size: 27px;
  letter-spacing: -0.7px;
  color: #090a3c;
  -webkit-tap-highlight-color: transparent;
}

.measure .input::-webkit-input-placeholder {
  color: #b3b3b3;
}

.measure .input:-ms-input-placeholder {
  color: #b3b3b3;
}

.measure .input::-ms-input-placeholder {
  color: #b3b3b3;
}

.measure .input::placeholder {
  color: #b3b3b3;
}

.measure .dropdown {
  width: 100%;
  min-width: 0;
  max-width: 430px;
  height: 100%;
  max-height: 250px;
  overflow-x: hidden;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  visibility: hidden;
  z-index: 10;
}

.measure .dropdown.v {
  visibility: visible;
}

.measure .dropdown_item {
  width: 100%;
  max-width: 407px;
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #b3b3b36b;
}

.measure .dropdown_item:hover {
  background-color: #f1f1f1d7;
}

.measure .item_text1 {
  color: #090a3c;
  margin-bottom: 5px;
}

.measure .item_text2 {
  font-size: 14px;
  color: #090a3c80;
}

.switch-field label {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  color: var(--Gunmetal);
  font-size: 16px;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  border: 1px solid var(--Magnolia);
  height: 55px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked+label {
  background: #f2f6ff;
  border: 1px solid #2d4bf3;
  color: var(--Gunmetal);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 15px 0;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

@media (max-width: 400px) {
  .latest_estate .new_estates {
    display: block;
  }

  .latest_estate .new_estates .ff_part {
    width: 100%;
  }

  .latest_estate .new_estates .ff_part2 {
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 540px) {
  .acct_info .create_img {
    display: none;
  }

  .form_frame .renovated form {
    padding: 20px;
  }

  .perTask .main_drop {
    margin-top: 30px;
  }

  .with_blue {
    margin-top: 25px;
  }

  .call_text {
    width: 10%;
  }

  .group_profile .admins_list .per_admin .name_pic {
    width: 88%;
  }

  .group_profile .admins_list .per_admin .name_pic img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .group_profile .admins_list .per_admin .name_pic .ad_name h4 {
    font-size: 17px;
  }

  .group_profile .admins_list .per_admin {
    padding: 15px 10px;
  }

  .mail_none {
    display: none;
  }

  .dashboard_container {
    padding: 0 20px;
  }

  .acct_info .create_bg {
    width: 100%;
  }

  .acct_info .create_bg .create_est form {
    position: static !important;
    padding: 20px;
  }
}

@media (min-width: 768px) {

  .fourcard_grid,
  .threecard_grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: left;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .fourcard_grid .grid-row,
  .threecard_grid .grid-row {
    margin-bottom: 20px;
    width: 47%;
  }

  .estate_cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .estate_cards .per_estate {
    width: 48.5%;
  }
}

@media (max-width: 768px) {
  .change_ratio .control_view {
    display: none;
  }

  .navbar_container {
    display: none;
  }

  .bills_on_me {
    width: 100% !important;
  }

  .new_estates_form {
    padding: 30px 20px;
  }

  .change_ratio .selected_tab {
    width: 100% !important;
  }
}

@media (max-width: 890px) {
  .acct_info .create_bg .create_est form {
    position: absolute;
    max-width: 470px;
  }

  .acct_info .create_bg {
    min-height: 100vh;
  }

  .acct_info .create_img img {
    height: 100%;
  }

  .acct_info .create_bg .create_est {
    padding: 20px;
  }

  .acct_info {
    min-height: 100vh;
  }

  .adding_new .important-btn {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
  }
}

@media (max-width: 1024px) {
  .control_view .desktop_nav h4 {
    display: none;
  }

  .for_desktops {
    display: none;
  }

  .bills_on_me {
    width: 87%;
  }

  .change_ratio .control_view {
    width: 13%;
  }

  .change_ratio .selected_tab,
  .navbar_container {
    background-color: var(--White);
    width: 87%;
  }

  #option div {
    text-align: center;
    display: block;
  }

  #option svg {
    margin: 0;
  }
}

@media (min-width: 540px) {
  .group_profile .Cap_estate_name .resident_data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .group_profile .Cap_estate_name .resident_data .per {
    width: 50%;
  }

  .download_filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .download_filter h6 {
    opacity: 0.4;
  }

  .download_filter .filter_download {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .download_filter .filter_drop {
    width: 170px;
    margin-right: 10px;
  }

  .download_filter .with_blue {
    width: 200px;
    margin: 0;
  }

  .download_filter .with_blue .select-btn {
    background: var(--Brand-color);
    border: 0;
    color: var(--White);
  }
}

@media (min-width: 540px) {
  .dispatch_svg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .dispatch_svg p {
    margin-top: 0;
  }

  .dispatch_svg .svg {
    width: 15%;
    margin-top: 15px;
  }

  .dispatch_svg .choose_txt {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .for_phone {
    display: none;
  }

  .fourcard_grid .grid-row,
  .threecard_grid .grid-row {
    width: 32.5%;
  }

  .estate_cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .estate_cards .per_estate {
    width: 32.5%;
  }
}

.superadmin_logo {
  margin-bottom: 80px !important;
}

@media (max-width: 1200px) {
  .create_img img {
    display: none !important;
  }
}

@media (min-width: 1201px) {
  .fourcard_grid .grid-row {
    width: 24%;
  }

  .form_frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .form_frame .renovated {
    width: 45%;
  }

  .form_frame_edit .renovated {
    width: 200% !important;
  }

  .form_frame .create_img {
    width: 55%;
  }

  .form_frame .create_img img {
    height: 100%;
  }

  .group_profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1400px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .group_profile .Cap_estate_name {
    width: 53%;
  }

  .group_profile .admins_list {
    margin-top: 0;
  }
}

@media (min-width: 1400px) {
  .chartbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .chartbox .chart {
    width: 58%;
  }

  .chartbox .latest_estate {
    width: 40%;
  }

  .estate_cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .estate_cards .per_estate {
    width: 24.5%;
  }
}

.swing-in-bottom-fwd {
  -webkit-animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation swing-in-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-bottom-fwd {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 1;
  }
}

@keyframes swing-in-bottom-fwd {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 1;
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**
 * -----------------------
 * animation slide-in-top
 * ----------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


@media screen and (max-width : 400px) {
  .fourcard_grid {
    margin: 10px 0;
  }

}


.checkbox_item .checkbox_wrap {
  position: relative;
  display: block;
  cursor: pointer;
  width: 100px;
  margin: 0 auto;
}

.checkbox_item .checkbox_wrap .checkbox_inp {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
}

.checkbox_item .checkbox_wrap .checkbox_mark {
  display: inline-block;
  position: relative;
  border-radius: 25px;
}

.checkbox_item .checkbox_wrap .checkbox_mark:before,
.checkbox_item .checkbox_wrap .checkbox_mark:after {
  content: "";
  position: absolute;
  transition: all 0.5s ease;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark {
  background: #f0f0f0;
  width: 80px;
  height: 35px;
  padding: 2px;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark:before {
  top: 6px;
  left: 3px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50%;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked~.checkbox_mark {
  background: var(--Brand-color);
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked~.checkbox_mark:before {
  left: 55px;
}