@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

:root {
  --Brand-color: #2d4bf3;
  --TurkishRose: #bc658d;
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --White: #ffffff;
  --Rhythm: #69779b;
  --CaribbeanGreen: #00c5a2;
  --OldLavender: #756c83;
  --AmericanPurple: #432160;
  --ChineseBlue: #415b90;
  --DarkSlateGray: #314a52;
  --MaximumRedPurple: #a03c78;
  --DarkLavender: #6f539b;
  --MetallicBlue: #3c5186;
  --TiffanyBlue: #0fabbc;
  --PoliceBlue: #394a6d;
  --Corn: #fbeb5b;
  --LimeGreen: #4be133;
  --OldRose: #394a6d;
  --Magnolia: #f4f3fe;
  --ChineseSilver: #cccccc;
  --LavenderGray: #c5c0db;
  --Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
  --blue: rgb(26, 57, 87);
  --white: rgb(236, 236, 236);
  --DeepGreen-CyanTurquoise: #206d62;
  --Satoshi: "Satoshi", sans-serif;
  --DM-Sans: "DM Sans", sans-serif;
  --shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  --overlay: rgba(44, 51, 58, 0.2);
}

[dark-theme="dark"] {
  --White: #2c333a;
  --Gunmetal: #ffffff;
  --White: #2c333a;
}

body {
  background-color: var(--White);
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  overflow: -moz-scrollbars-vertical;
  -webkit-tap-highlight-color: transparent;
}

.webContainer {
  max-width: 1210px;
  padding: 0 20px;
  margin: 0 auto;
}

@media (max-width: 540px) {
  .webContainer {
    padding: 0 20px;
  }
}

.rendered {
  position: relative;
}

.rendered:before {
  content: url("../svg/estate_bLogo.svg");
  position: absolute;
}

.paymentChecks {
  margin-bottom: 20px;
}

.paymentChecks .cntr {
  margin: 10px 0;
}

.invoiceFor {
  background: url("../images/receiptBoard.png") no-repeat;
  background-size: cover;
}

.handleNavbar {
  font-family: var(--Satoshi) !important;
}

#messagesBg {
  position: relative;
}

#messagesBg:before {
  content: url("../svg/message_badge.svg");
  position: absolute;
  left: 8px;
  top: 16px;
}

#alertBg {
  position: relative;
}

#alertBg:before {
  content: url("../svg/caution.svg");
  position: absolute;
  left: 8px;
  top: 16px;
}

.notification.active {
  display: block !important;
}

#greenBgC {
  color: #00ba07;
}

#redBgC {
  color: #f3d645;
}

#greenBg {
  background: #cef3cf;
  border: 1px solid #00ba07;
  border-radius: 4px;
}

#redBg {
  background: #fffbe6;
  border: 1px solid #f3d645;
  border-radius: 4px;
}

.defaultWidth {
  width: 266px;
}

.paginationButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 452px;
  margin: 30px auto;
}

.paginationButtons .next,
.paginationButtons .previous {
  background-color: var(--Brand-color);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding: 5px 15px;
}

.paginationButtons .next:hover,
.paginationButtons .previous:hover {
  background: var(--Brand-color);
}

.paginationButtons li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.018em;
  padding: 5px 10px;
  margin: 3px;
  cursor: pointer;
}

.paginationButtons li:hover {
  background: #f3f3f3;
}

.paginationButtons .paginationActive {
  font-weight: 600;
  border-bottom: 2px solid var(--Brand-color);
}

.DarkLavender {
  background-color: var(--DarkLavender);
}

.DeepGreen-CyanTurquoise {
  background-color: var(--DeepGreen-CyanTurquoise);
}

.LavenderGray {
  background-color: var(--LavenderGray);
}

.DarkSlateGray {
  background-color: var(--DarkSlateGray);
}

.ChineseBlue {
  background-color: var(--ChineseBlue);
}

.ChineseSilver {
  background-color: var(--ChineseSilver);
}

.Corn {
  background-color: var(--Corn);
}

.Magnolia {
  background-color: var(--Magnolia);
}

.OldRose {
  background-color: var(--OldRose);
}

.LimeGreen {
  background-color: var(--LimeGreen);
}

.PoliceBlue {
  background-color: var(--PoliceBlue);
}

.TiffanyBlue {
  background-color: var(--TiffanyBlue);
}

.MetallicBlue {
  background-color: var(--MetallicBlue);
}

.MaximumRedPurple {
  background-color: var(--MaximumRedPurple);
}

.DarkCyan {
  background-color: var(--Brand-color);
}

.TurkishRose {
  background-color: var(--TurkishRose);
}

.Rhythm {
  background-color: var(--Rhythm);
}

.CaribbeanGreen {
  background-color: var(--CaribbeanGreen);
}

.AmericanPurple {
  background-color: var(--AmericanPurple);
}

.TurkishRose {
  background-color: var(--TurkishRose);
}

.Gunmetal {
  background-color: var(--Gunmetal);
}

.ViridianGreen {
  background-color: var(--ViridianGreen);
}

.Rhythm {
  background-color: var(--Rhythm);
}

.CaribbeanGreen {
  background-color: var(--CaribbeanGreen);
}

.OldLavender {
  background-color: var(--OldLavender);
}

.White {
  background-color: var(--White);
  color: var(--White);
}

.WhiteC {
  color: var(--White);
}

a {
  color: unset;
}

.insta_svg path {
  fill: #009ba2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--Satoshi);
  color: var(--Gunmetal);
  font-weight: 500;
  line-height: 110%;
}

h1 {
  font-size: 48px;
}

h2 {
  font-weight: 500;
  font-size: 43px;
  margin-bottom: 30px;
  line-height: 52px;
}

h3 {
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 20px;
}

h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
}

h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 20px;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.op-5 {
  opacity: 50%;
}

.max_605 {
  max-width: 605px;
}

.max_700 {
  max-width: 700px;
}

hr {
  border: 0.5px solid rgba(44, 51, 58, 0.5);
  margin: 40px 0;
}

ul li {
  font-family: var(--DM-Sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 8px 0;
  letter-spacing: 0em;
  text-align: left;
}

a,
label,
::-webkit-input-placeholder {
  font-family: var(--DM-Sans);
}

a,
label,
:-ms-input-placeholder {
  font-family: var(--DM-Sans);
}

a,
label,
::-ms-input-placeholder {
  font-family: var(--DM-Sans);
}

a,
label,
::placeholder {
  font-family: var(--DM-Sans);
}

ol {
  list-style: none;
  counter-reset: item;
}

ol li {
  counter-increment: item;
  margin-bottom: 5px;
  font-family: var(--Satoshi);
}

ol li:before {
  margin-right: 16px;
  margin-top: 6px;
  content: counter(item);
  background: var(--Gunmetal);
  border-radius: 100%;
  color: white;
  font-weight: 700;
  width: 25px;
  height: 25px;
  text-align: center;
  display: inline-block;
}

.display_pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

p,
span,
li {
  font-family: var(--DM-Sans);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 8px 0;
  letter-spacing: 0em;
  text-align: left;
}

img {
  width: 100%;
  max-width: 100%;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.b_location::before {
  width: 24px;
  height: 24px;
  position: relative;
  top: 5px;
  margin-right: 8px;
}

.ct-series-a .ct-line {
  stroke: var(--Gunmetal) !important;
  stroke-width: 2px;
}

.ct-grids .ct-horizontal {
  stroke-width: 0px !important;
  stroke-dasharray: 5px;
}

.ct-series-a .ct-point {
  stroke-linecap: circle;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  stroke-width: 10px;
  stroke: var(--MaximumRedPurple) !important;
  cursor: pointer;
}

.ct-series-a .ct-point::before {
  content: "Hello";
  color: #009ba2;
}

.ct-series-a .ct-point:hover {
  stroke-width: 15px;
  stroke: var(--ChineseBlue) !important;
}

.b_phone::before {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.b_email::before {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.b_module::before {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-6 {
  margin-top: 60px;
}

.mt-7 {
  margin-top: 70px;
}

.mt-8 {
  margin-top: 80px;
}

.mt-9 {
  margin-top: 90px;
}

.mt-10 {
  margin-top: 100px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mb-6 {
  margin-bottom: 60px;
}

.mb-7 {
  margin-bottom: 70px;
}

.mb-8 {
  margin-bottom: 80px;
}

.mb-9 {
  margin-bottom: 90px;
}

.mb-10 {
  margin-bottom: 100px;
}

.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pt-4 {
  padding-top: 40px;
}

.pt-5 {
  padding-top: 50px;
}

.pt-6 {
  padding-top: 60px;
}

.pt-7 {
  padding-top: 70px;
}

.pt-8 {
  padding-top: 80px;
}

.pt-9 {
  padding-top: 90px;
}

.pt-10 {
  padding-top: 100px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.pb-5 {
  padding-bottom: 50px;
}

.pb-6 {
  padding-bottom: 60px;
}

.pb-7 {
  padding-bottom: 70px;
}

.pb-8 {
  padding-bottom: 80px;
}

.pb-9 {
  padding-bottom: 90px;
}

.pb-10 {
  padding-bottom: 100px;
}

button {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

button:disabled {
  opacity: 0.5;
  background-color: unset;
  color: var(--ChineseSilver);
  border: 1px solid rgba(204, 204, 204, 0.4);
}

.icon {
  width: 24px;
  height: 24px;
}

.btn_mid {
  text-align: center;
}

.handleText h5 {
  background: url("../svg/quote.svg") no-repeat;
}

.default-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: var(--ChineseSilver);
}

.important-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: var(--Brand-color);
  color: var(--White);
}

.outlined-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
  background-color: var(--White);
  color: var(--Brand-color);
  border: 2px solid var(--Brand-color);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.outlined-btn:hover {
  color: var(--White);
  background-color: var(--Brand-color);
}

.primary-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: var(--Gunmetal);
  color: var(--White);
}

.danger-btn {
  padding: 12px 35px;
  margin: 25px 0 10px 0;
  border-radius: 5px;
  border: 0;
  background-color: unset;
  font-family: var(--Satoshi);
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background-color: #ea5f5e;
  color: var(--White);
}

header {
  background: var(--Glass);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  width: 100%;
  z-index: 99;
  height: 80px;
  position: fixed;
  top: 0;
  -webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
    0px 2px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
}

nav {
  height: auto;
  padding: 18px 0;
  background-color: var(--White);
  position: fixed;
  top: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 12;
}

nav .navbar img {
  width: 177px;
  height: 25px;
  -o-object-fit: cover;
  object-fit: cover;
}

nav h2 {
  font-size: 30px;
  width: 177px;
  margin: 15px 0;
  text-align: left;
  font-family: var(--Satoshi);
}

.navlinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 50%;
}

.navlinks a:active {
  color: var(--Brand-color);
}

.navlinks img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.navlinks li {
  margin: 25px 0;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--DM-Sans);
  opacity: 0.9;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.navlinks li::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0%;
  height: 3px;
  background: var(--Main-Gradient);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  bottom: 0;
}

.navlinks li:hover::before {
  opacity: 1;
  width: 100%;
}

.navlinks li:hover {
  font-weight: 700;
}

.navlinks li:active {
  border-bottom: var(--Brand-color);
}

.select_drop {
  font-family: var(--DM-Sans);
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select_drop .dropdown-btn {
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  font-weight: 500;
  border: 1px solid var(--Gunmetal);
  background-color: var(--White);
  color: var(--Gunmetal);
}

.select_drop .dropdown-content {
  position: absolute;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  top: 50px;
  padding: 10px;
  background-color: var(--White);
  width: 100%;
}

.select_drop .dropdown-item {
  padding: 10px;
  cursor: pointer;
  -webkit-transform: 0.4s;
  transform: 0.4s;
}

.select_drop .dropdown-item:hover {
  background-color: var(--Magnolia);
}

.btn_only {
  --r: 5px;
  /* radius */
  --b: 2px;
  /* border width */
  background: var(--Main-Gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  border-width: 1px;
  border-style: solid;
  -o-border-image: var(--Main-Gradient);
  border-image: var(--Main-Gradient);
  margin: 15px 0;
  border-radius: var(--r);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  position: relative;
  z-index: 0;
  text-decoration: none;
  padding: 15px 30px;
  margin-right: auto;
  margin-left: auto;
}

/* check lined question for the detail of the below code */
.btn_only::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  bottom: 0;
  border-radius: var(--r);
  border-width: 1px;
  border-style: solid;
  -o-border-image: var(--Main-Gradient);
  border-image: var(--Main-Gradient);
  border-radius: var(--r);
  background: inherit;
  background-origin: border-box;
  background-clip: border-box;
  -webkit-mask: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #fff)) padding-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  -webkit-mask-repeat: no-repeat;
}

/**/
.btn_only:hover {
  color: #fff;
  -webkit-text-fill-color: #fff;
  background-clip: border-box;
}

.btn_only:hover::before {
  -webkit-mask: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.track {
  color: var(--Brand-color);
  border-bottom: 3px solid var(--Brand-color);
}

.hamburger {
  margin-top: 0px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: var(--Gunmetal);
  margin: 5px;
}

ul.breadcrumb {
  list-style: none;
  margin-bottom: 40px;
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  color: var(--Gunmetal);
}

ul.breadcrumb li+li:before {
  content: url("https://www.svgrepo.com/show/356409/arrow-right.svg");
  width: 20px;
  position: relative;
  top: 4px;
  left: -6px;
  height: 35px;
  display: block;
  padding: 0 10px;
  display: inline-block;
}

ul.breadcrumb li a {
  color: var(--ChineseSilver);
  text-decoration: none;
  -webkit-transition: 0.7s ease;
  transition: 0.7s ease;
}

ul.breadcrumb li a:hover {
  color: var(--ChineseSilver);
  text-decoration: underline;
}

#myProgress {
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  height: 8px;
  background-color: #f4f4f4;
}

#myProgress #myBar {
  width: 10%;
  border-radius: 4px;
  height: 8px;
  background-color: var(--Brand-color);
}

.border-top {
  border-top: 1px solid var(--ChineseSilver);
  margin-bottom: 40px;
}

.c_p {
  cursor: pointer;
}

.tab {
  float: left;
}

.tab__group {
  position: relative;
  height: 340px;
}

.tab__group:after {
  content: "";
  display: block;
  clear: both;
}

.tab__label {
  display: inline-block;
  padding: 0 20px;
  margin-right: 5px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  cursor: pointer;
  color: var(--Brand-color);
  border-bottom: 3px solid var(--Brand-color);
}

.tab__content {
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0;
  right: 0;
  background: #fff;
}

.tab__radio {
  display: none;
}

.tab__radio:not(:checked)~.tab__label {
  font-size: 16px;
  font-weight: 400;
  color: var(--Gunmetal);
  background: transparent;
  border-bottom: 0;
}

.tab__radio:not(:checked)~.tab__content {
  display: none;
}

.container {
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 20px;
}

#overlay_ {
  display: none;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
}

.overlay .it_card {
  background-color: var(--White);
  padding: 20px;
  margin: 25px;
  top: 20%;
  position: absolute;
  max-width: 550px;
  width: 90%;
}

.overlay .it_card .it_top {
  height: 70px;
  background-color: var(--ChineseSilver);
  text-align: center;
  padding-top: 20px;
}

.overlay .it_card .it_top h3 {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.overlay .it_card .it_content {
  padding: 20px;
}

.overlay .it_card .bbtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.overlay .it_card .bbtn button {
  width: 25%;
  margin-right: 45px;
}

.overlay .it_card .bbtn div {
  margin-top: 40px;
  font-weight: 600;
  cursor: pointer;
  font-family: var(--DM-Sans);
}

.entry {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.06em;
  text-align: left;
  position: relative;
  color: #39e040;
}

.entry::before {
  content: url("../svg/entry_thumb.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -35px;
  top: -5px;
}

.exit {
  color: red;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.06em;
  text-align: left;
  position: relative;
}

.exit::before {
  content: url("../svg/red_exit.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -35px;
  top: -5px;
}

table {
  border-collapse: collapse;
  font-family: var(--Satoshi);
  margin: 25px 0;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

table thead tr {
  background-color: var(--LavenderGray);
  text-align: left;
  border: 1px solid rgba(44, 51, 58, 0.1);
  color: var(--Gunmetal);
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

table thead tr:nth-of-type(1) {
  background-color: #f3f3f3 !important;
}

table tbody tr {
  border: 1px solid rgba(44, 51, 58, 0.1);
  font-size: 15px;
  font-weight: 500;
}

table tbody tr:nth-of-type(even) {
  background-color: #f2f2f2 !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

table th,
table td {
  padding: 18px;
}

.pagination {
  display: inline-block;
  width: 100%;
  margin: 30px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.pagination a {
  color: black;
  float: left;
  font-weight: 600;
  padding: 8px 16px;
  margin: 0 5px;
  text-decoration: none;
}

.pagination a:hover {
  color: var(--Brand-color);
}

.testimonial_box {
  margin-top: 70px;
  margin-bottom: 40px;
}

.testimonial_box .p_reviews {
  max-width: 410px;
}

.testimonial_box .p_reviews h4 {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.testimonial_box .t_avatars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
}

.testimonial_box .t_avatars .avatars__image {
  -webkit-box-shadow: 0 0 0 4px #fff;
  box-shadow: 0 0 0 4px #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 9999px;
}

.testimonial_box .t_avatars .avatars__image p {
  margin-top: 10px;
  font-weight: 700;
  font-size: 18px;
}

.testimonial_box .t_avatars .avatars__image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid var(--White);
}

.testimonial_box .t_avatars .avatars__image .lap_2 {
  position: relative;
  left: -8px;
}

.testimonial_box .t_avatars .avatars__image .lap_3 {
  position: relative;
  left: -16px;
}

.testimonial_box .t_avatars .avatars__image .lap_4 {
  position: relative;
  left: -24px;
}

.testimonial_box .t_avatars .avatars__image .lap_5 {
  position: relative;
  left: -32px;
}

.testimonial_box .t_avatars .avatars__image .lap_6 {
  position: relative;
  left: -40px;
}

.testimonial_box .t_avatars .avatars__image .lap_7 {
  position: relative;
  left: -48px;
}

.uni_results {
  z-index: 10;
  display: none;
  position: absolute;
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  background-color: var(--White);
  top: 0;
  -webkit-animation: uni_results 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: uni_results 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.uni_results ul {
  margin-bottom: 20px;
}

.uni_results ul li {
  padding: 10px;
  margin: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.uni_results ul li:hover {
  background-color: var(--Magnolia);
}

.show .uni_results {
  display: block;
}

@-webkit-keyframes uni_results {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}

@keyframes uni_results {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
}

.pagination a.active {
  border-bottom: 3px solid var(--Brand-color);
  font-weight: 600;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.top_tool::before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: var(--ChineseSilver);
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 90%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.accordion {
  width: 100%;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  border: 1px solid var(--Magnolia);
  margin: 40px 0;
}

.accordion h3 {
  background-color: var(--Magnolia);
  margin: 0;
  padding: 20px;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}

.accordion input {
  display: none;
}

.acc_label,
.acc_content {
  padding: 14px 20px;
}

.acc_label input,
.acc_content input {
  display: block;
}

.acc_label {
  display: block;
  color: var(--Gunmetal);
  font-weight: 500;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  background-color: var(--White);
  border: 1px solid var(--Magnolia);
}

.acc_label::after {
  content: "+";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  font-size: 20px;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.acc_label:hover {
  background: var(--Brand-color);
  color: var(--White);
}

.acc_content {
  background-color: var(--Brand-color);
  color: white;
  font-weight: 0.85em;
  line-height: 1.6;
  display: none;
}

.acc_content p {
  color: var(--White);
}

.acc_input:checked~.acc_label {
  background-color: var(--Brand-color);
  color: var(--White);
}

.acc_input:checked~.acc_content {
  display: block;
  background-color: var(--Brand-color);
}

.acc_input:checked~.acc_label::after {
  content: "-";
  -webkit-transform: translateY(-50%) rotate(0.5turn);
  transform: translateY(-50%) rotate(0.5turn);
}

.bottom_tool::before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: var(--ChineseSilver);
  display: inline-block;
  position: absolute;
  bottom: -12px;
  left: 48%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.right_tool::before {
  content: "";
  width: 24px;
  height: 24px;
  background-color: var(--ChineseSilver);
  display: inline-block;
  position: absolute;
  right: -12px;
  top: 48%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tools_tip {
  background-color: var(--ChineseSilver);
  padding: 16px;
  border-radius: 5px;
  margin: 30px 0;
  position: relative;
}

.tools_tip p {
  margin: 0;
}

.single_form {
  margin-bottom: 25px;
}

.single_form h1 {
  font-size: 25px !important;
}

pre {
  background-color: #f3f3f3;
  font-size: 18px;
  padding: 20px;
  max-height: 450px;
  overflow: scroll;
}

.scrollable_table {
  overflow: auto;
}

.scrollable_table p {
  margin: 0 !important;
}

@media (max-width: 1200px) {
  .scrollable_table table {
    min-width: 1200px;
  }
}

.input_parent {
  margin: 25px 0;
  height: 50px;
  font-style: var(--DM-Sans);
  position: relative;
  background-color: transparent;
}

.input_parent label {
  position: absolute;
  left: 15px;
  z-index: -2;
  top: 15px;
  -webkit-transition: top 0.4s;
  transition: top 0.4s;
}

input {
  display: block;
  width: 100%;
  height: auto;
  padding: 15px;
  font-size: 16px;
  line-height: 1.4;
  color: var(--Gunmetal);
  background-color: #fff;
  border: 1px solid #dfe3e7;
  border-radius: 5px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

input:focus {
  outline: var(--Brand-color);
}

input::-webkit-input-placeholder {
  color: #2c333a;
  opacity: 0.6;
}

input:-ms-input-placeholder {
  color: #2c333a;
  opacity: 0.6;
}

input::-ms-input-placeholder {
  color: #2c333a;
  opacity: 0.6;
}

input::placeholder {
  color: #2c333a;
  opacity: 0.6;
}

.arrowDown {
  top: 11px !important;
}

.defaultforDate {
  width: 150px;
}

.search_set {
  border: 1px solid #d8d8d8;
  padding: 0 10px;
  border-radius: 5px;

  height: 46px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search_set img {
  width: 25px;
  margin-right: 0px;
  top: 6px;
}

.search_set input {
  border: 0;
  background: transparent !important;
  outline: 0;
  height: 43px;
  font-size: 16px;
  padding: 10px;
}

.select_me {
  width: 200px;
  position: relative;
  font-family: var(--Nunito);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select_me h5 {
  margin-left: 10px;
  margin-bottom: 5px;
}

.select_me .select-btn {
  padding: 10px;
  background: var(--White);
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(44, 51, 58, 0.2);
  font-size: 16px;
  font-weight: 400;
  position: relative;
  line-height: 24px;
  letter-spacing: 0px;
  height: 45px;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--Gunmetal);
}

.select_me .select-btn span {
  position: absolute;
  left: 20px;
}

.select_me .select-btn img {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
}

.select_me .select-btn input {
  width: 90% !important;
  background: transparent;
  padding: 0;
  outline: 0;
  font-size: 16px;
  border: 0;
  margin: 0 !important;
}

.select_me .select_content {
  position: absolute;
  top: 55px;
  padding: 10px;
  z-index: 1;
  width: 100%;
  left: 0;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  overflow: auto;
}

.select_me .select_items {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 3px;
  translate: scale(1);
  transition: 0.4s;
}

.select_me .select_items:hover {
  translate: scale(1.9);
  background: #f2f6ff;
  border: 1px solid #2d4bf3;
  border-radius: 5px;
}

.radio {
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

.radio p {
  opacity: 50%;
  margin-top: 0;
  margin-left: 40px;
}

.radio input {
  display: none;
}

.radio input:disabled {
  display: none;
}

.radio input+span {
  display: inline-block;
  position: relative;
  padding-left: 40px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}

.radio input+span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--Brand-color);
}

.radio input+span::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--Brand-color);
  position: absolute;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.radio input+h3 {
  display: block;
  position: relative;
  padding-top: 23px;
  margin: 10px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 5px;
}

.radio input+h3::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid var(--Brand-color);
}

.radio input+h3::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--Brand-color);
  position: absolute;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.radio input:checked+span::after,
.radio input:checked+h3::after {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio_disabled {
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

.radio_disabled input:disabled {
  display: none;
}

.radio_disabled input:disabled+span {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
}

.radio_disabled input:disabled+span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  background-color: #f4f3fe;
}

.radio_disabled input:disabled+span::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #c5c0db;
  position: absolute;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.check_parent .checkbox {
  display: block;
  font-family: var(--Satoshi);
  position: relative;
  padding-left: 40px;
  padding-top: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 16px;
  font-weight: 400;
  -webkit-user-select: none;
  border-radius: 5px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_parent .checkbox p {
  opacity: 50%;
}

.check_parent .checkbox input {
  position: absolute;
  width: auto;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  opacity: 0;
  border-radius: 5px;
  cursor: pointer;
}

.check_parent .checkbox input:checked~.checkmark {
  background: var(--Main-Gradient);
  border: 0;
}

.check_parent .checkbox input:checked~.checkmark:after {
  display: block;
}

.check_parent .checkbox input:disabled~.checkmark {
  background-color: var(--White);
}

.check_parent .checkbox .checkmark {
  position: absolute;
  top: 0;
  cursor: pointer;
  left: 0;
  border-radius: 5px;
  height: 25px;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  width: 25px;
  background-color: var(--White);
  border: 1px solid var(--Gunmetal);
}

.check_parent .checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mainPin,
.unitData,
textarea {
  font-family: var(--DM-Sans);
}

input:focus~label {
  top: -15px;
  color: var(--Brand-color);
  background-color: white;
  padding: 5px;
  font-size: 14px;
  z-index: 2;
}

input:focus {
  background-color: transparent;
  z-index: 2;
  border-radius: 5px;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

input:focus::-ms-input-placeholder {
  color: transparent;
}

input:focus::placeholder {
  color: transparent;
}

input:valid {
  background-color: #ffffff;
}

.custom-select {
  position: relative;
  font-family: Arial;
  border: 1px solid var(--Gunmetal);
  border-radius: 5px;
  min-width: 130px;
}

.select_me {
  width: 100%;
  position: relative;
  font-family: var(--Nunito);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select_me .select-btn {
  padding: 10px;
  background: var(--White);
  border-radius: 5px;
  border: 1px solid rgba(44, 51, 58, 0.2);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: var(--Gunmetal);
}

.select_me .select-btn img {
  width: 24px;
  height: 24px;
}

.select_me .select_content {
  position: absolute;
  top: 55px;
  padding: 10px;
  width: 100%;
  left: 0;
  font-weight: 500;
  background: #ffffff;
  max-height: 200px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  overflow: auto;
}

.select_me .select_content p {
  margin: 0 !important;
}

.select_me .select_items {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-bottom: 1px solid #f5f5f5;
}

.select_me .select_items:hover {
  background-color: rgba(0, 131, 143, 0.07);
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: transparent;
  border: 1px solid rgba(44, 51, 58, 0.7);
  border-radius: 5px;
  font-family: var(--DM-Sans);
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: var(--Gunmetal) transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent var(--Gunmetal) transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: var(--Gunmetal);
  padding: 8px 16px;
  border: 1px solid var(--Gunmetal);
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: var(--Magnolia);
  top: 100%;
  left: 0;
  right: 0;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.search_parent {
  position: relative;
}

.search_parent input {
  padding: 15px 20px 15px 48px;
}

.search_parent img {
  width: 20px;
  height: 24px;
  position: absolute;
  top: 15px;
  left: 16px;
}

.password_parent {
  border-radius: 5px;
  height: 50px;
  width: 100%;
  position: relative;
  background-color: transparent;
  margin: 20px 0 10px 0;
}

.password_parent input {
  width: 100%;
  padding: 15px;
  border: 0;
  outline: 0;
  border: 1px solid var(--ChineseSilver);
  background-color: transparent;
}

.password_parent input:focus~label {
  top: -12px;
  color: var(--Brand-color);
  z-index: 2;
}

.password_parent input:focus {
  background-color: var(--ChineseSilver);
  border: 1px solid var(--Brand-color);
  z-index: 2;
}

.password_parent input:focus::-webkit-input-placeholder {
  color: transparent;
}

.password_parent input:focus:-ms-input-placeholder {
  color: transparent;
}

.password_parent input:focus::-ms-input-placeholder {
  color: transparent;
}

.password_parent input:focus::placeholder {
  color: transparent;
}

.password_parent input:valid {
  background-color: var(--White);
}

.password_parent span {
  margin: 5px 0;
  color: red;
  opacity: 0;
}

.password_parent .tribute {
  position: absolute;
  right: 30px;
  top: 13px;
}

.password_parent.active input {
  border: 1px solid red;
}

.password_parent.active input:focus~label {
  top: -12px;
  color: red;
  z-index: 2;
}

.password_parent.active span {
  opacity: 1;
}

.password_parent label {
  position: absolute;
  left: 15px;
  z-index: -2;
  top: 15px;
  -webkit-transition: top 0.4s;
  transition: top 0.4s;
}

#hide {
  display: none;
}

.form_x h3 {
  margin-bottom: 30px;
}

.form_x h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  margin: 20px 0 0 0;
  text-align: left;
}

.switch_parent .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  margin-top: 20px;
  height: 24px;
}

.switch_parent .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch_parent .switch input:checked+.slider {
  background: var(--Main-Gradient);
}

.switch_parent .switch input:focus+.slider {
  -webkit-box-shadow: 0 0 1px var(--Main-Gradient);
  box-shadow: 0 0 1px var(--Main-Gradient);
}

.switch_parent .switch input:checked+.slider:before {
  -webkit-transform: translateX(24px);
  transform: translateX(24px);
}

.switch_parent .switch input:disabled+.slider:before {
  -webkit-transform: translateX(24px);
  transform: translateX(24px);
  opacity: 50%;
}

.switch_parent .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 24px;
  border-radius: 50px;
  background: rgba(204, 204, 204, 0.4);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_parent .switch .slider:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_parent .switch .slider p {
  margin: 0 0 0 60px;
  font-weight: 400;
}

/* -----Search-filter */
.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 1.4;
  color: #5376e5;
  background-color: #fff;
  border: 1px solid #dfe3e7;
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #5376e5;
  background-color: #fff;
  border-color: #2d4bf3;
  outline: 0;
}

.card {
  -webkit-box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  background-color: #fff;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #5376e5;
  overflow: hidden;
}

.pagination-data {
  padding: 0;
  margin: 0;
}

.pagination-data li {
  list-style: none;
}

.table-filter-info {
  padding: 15px;
}

.thead-primary tr th {
  background-color: #2d4bf3;
  border-color: #2d4bf3;
  color: #fff;
}

.rc-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  margin-bottom: 50px !important;
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-total-text {
  margin-right: 12px;
  cursor: initial;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}

.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
  display: block;
  content: "...";
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
  min-width: initial;
  height: auto;
  line-height: initial;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
  padding: 6px 8px;
  height: auto;
  min-width: 32px;
  min-height: 32px;
  border: 1px solid transparent;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #656f84 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}

.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
  border-bottom: 2px solid #2d4bf3;
}

.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
  background-color: #eceff5;
  border-color: #eceff5;
}

.main_drop {
  max-width: 120px;
  font-family: var(--Montserat);
  width: 100%;
  position: relative;
}

.main_drop .main_drop_btn {
  padding: 10px 12px;
  background-color: var(--White);
  border: 1px solid var(--Magnolia);
  border-radius: 5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main_drop .main_drop_btn img {
  width: 20px;
  height: 20px;
}

.main_drop .selections {
  border-radius: 6px;
  background-color: #fff;
  position: absolute;
  top: 52px;
  z-index: 11;
  border: 1px solid var(--Magnolia);
  width: 100%;
}

.main_drop .selections .selected {
  padding: 10px 12px;
  cursor: pointer;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.main_drop .selections .selected:hover {
  background-color: var(--Magnolia);
}

@media (max-width: 768px) {
  .navlinks {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    min-width: 280px;
    position: fixed;
    background: var(--White);
    padding: 20px;
    min-height: 300px;
    top: 0;
    z-index: 90;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -webkit-transform: translateX(-768px);
    transform: translateX(-768px);
    left: 0;
  }

  .navlinks li {
    padding: 10px;
    margin: 0;
    border-bottom: 2px solid #f3f3f3;
  }

  .backgroundMask {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
  }

  .placeblur {
    display: block;
  }

  .menudrop {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  #from_mo e {
    display: none;
  }
}

.fbook {
  position: relative;
  padding-left: 30px;
}

.fbook::before {
  content: url("../svg/facebook.svg");
  position: absolute;
  left: 0;
}

.ftwiiter {
  position: relative;
  padding-left: 30px;
}

.ftwiiter::before {
  content: url("../svg/twitter.svg");
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 0;
}

.fInstagram {
  position: relative;
  padding-left: 30px;
}

.fInstagram::before {
  content: url("../svg/instagram.svg");
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 0;
}

.ghost {
  display: none;
}

@media (min-width: 767px) {
  .container {
    padding: 0 40px;
  }

  .navlinks {
    margin-right: 55px;
  }

  .wrap_moon {
    position: absolute;
    top: 5px;
    cursor: pointer;
    top: 5px;
    right: 45px;
  }

  .on_nav {
    display: none;
  }

  .hamburger {
    display: none;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0 60px;
  }
}

.usersName::before {
  content: url("../svg/estate_bLogo.svg");
  margin-right: 10px;
}

@media (min-width: 1024px) {
  .container {
    padding: 0 60px;
  }
}

.searchInput::before {
  content: "\1F50D";
  font-size: 1em;
  margin-right: 0.5em;
}

#swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.fade-in-bck {
  -webkit-animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.tracking-in-contract {
  -webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-14 13:30:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-4 13:49:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation tracking-in-contract
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-19 19:51:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in-bck
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

:root {
  --Brand-color: #2d4bf3;
  --TurkishRose: #bc658d;
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --White: #ffffff;
  --Rhythm: #69779b;
  --CaribbeanGreen: #00c5a2;
  --OldLavender: #756c83;
  --AmericanPurple: #432160;
  --ChineseBlue: #415b90;
  --DarkSlateGray: #314a52;
  --MaximumRedPurple: #a03c78;
  --DarkLavender: #6f539b;
  --MetallicBlue: #3c5186;
  --TiffanyBlue: #0fabbc;
  --PoliceBlue: #394a6d;
  --Corn: #fbeb5b;
  --LimeGreen: #4be133;
  --OldRose: #394a6d;
  --Magnolia: #f4f3fe;
  --ChineseSilver: #cccccc;
  --LavenderGray: #c5c0db;
  --Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
  --blue: rgb(26, 57, 87);
  --white: rgb(236, 236, 236);
  --DeepGreen-CyanTurquoise: #206d62;
  --Satoshi: "Satoshi", sans-serif;
  --DM-Sans: "DM Sans", sans-serif;
  --shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  --overlay: rgba(44, 51, 58, 0.2);
}

[dark-theme="dark"] {
  --White: #2c333a;
  --Gunmetal: #ffffff;
  --White: #2c333a;
}

body {
  background-color: var(--White);
}

:root {
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --Magnolia: #f4f3fe;
  --Gray: #f4f4f4;
  --White: #ffffff;
  --Nunito: "Nunito", sans-serif;
  --DM-Sans: "Montserrat", sans-serif;
}

#file-bulletlist-md-readme,
.gist-meta,
.gist-file {
  display: none;
}

footer {
  -webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
    0px 2px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  height: auto;
  background-color: var(--White);
  z-index: 10;
  margin-top: 100px;
  position: relative;
}

footer .InstinctHub_foot {
  width: 215px;
}

footer .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 255px;
  width: 100%;
  margin: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

footer .media img {
  width: 40px;
  height: 30px;
  -o-object-fit: cover;
  object-fit: cover;
}

footer .location {
  padding-left: 27px;
}

footer .location::before {
  content: url("../svg/location.svg");
  position: absolute;
  left: -5px;
}

footer .phone {
  padding-left: 27px;
}

footer .phone::before {
  content: url("../svg/phone.svg");
  position: absolute;
  left: -5px;
}

footer .email {
  padding-left: 27px;
}

footer .email::before {
  content: url("../svg/email.svg");
  position: absolute;
  left: -5px;
}

footer .reserved_foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
}

footer .reserved_foot .main {
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

footer .reserved_foot .main h5 {
  margin-right: 20px;
}

footer .reserved_foot .main ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

footer .reserved_foot .main ul li {
  margin-top: 0;
  padding: 0 10px;
}

footer .reserved_foot .main ul li::before {
  content: "";
  width: 5px;
  display: inline-block;
  background-color: rgba(44, 51, 58, 0.2);
}

footer .reserved {
  padding: 15px 0;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 0;
  background-color: var(--Gray);
  text-align: center;
}

footer .wrap_footer_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 60%;
}

footer .wrap_footer_icon img {
  width: 35px;
}

footer .quick_links {
  padding-top: 30px;
}

footer .quick_links h3 {
  margin-bottom: 20px;
  font-weight: 600;
}

footer .quick_links li {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 10px;
}

footer .quick_links li a {
  font-family: var(--Nunito);
}

footer .quick_links li:hover {
  color: var(--ViridianGreen);
  font-weight: 600;
}

footer .social_media {
  text-align: center;
}

footer .social_media .media {
  margin: 30px auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 16%;
}

footer .social_media .media img {
  width: 24px;
  height: 24px;
}

footer .copyright {
  text-align: center;
  padding: 15px 0;
  background-color: var(--Gunmetal);
}

footer .copyright h5 {
  margin: 0;
  color: var(--White);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
}

footer ul.breadcrumb {
  list-style: none;
  margin: 20px 0 40px 0;
  font-family: var(--Nunito);
}

footer ul.breadcrumb li {
  display: inline;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--DarkCyan);
}

footer ul.breadcrumb li+li:before {
  content: "";
  border: 1px solid var(--Gunmetal);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: var(--Gunmetal);
  display: inline-block;
  width: 0px;
  position: relative;
  top: -4px;
}

footer ul.breadcrumb li a {
  color: var(--Gunmetal);
  text-decoration: none;
  -webkit-transition: 0.7s ease;
  transition: 0.7s ease;
}

footer ul.breadcrumb li a:hover {
  color: var(--Gunmetal);
  text-decoration: underline;
}

@media (min-width: 580px) {
  .footer_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .footer_wrap .quick_links {
    padding-bottom: 40px;
    width: 44%;
  }
}

@media (max-width: 540px) {
  footer .reserved_foot .main {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  footer .media {
    max-width: 250px;
    margin: 20px auto;
  }
}

@media (min-width: 980px) {
  .footer_wrap .quick_links {
    width: 12%;
  }

  .it_logo {
    width: 23% !important;
  }

  .last-media {
    width: 23% !important;
  }
}

:root {
  --Brand-color: #2d4bf3;
  --TurkishRose: #bc658d;
  --Gunmetal: #2c333a;
  --ViridianGreen: #009ba2;
  --White: #ffffff;
  --Corn: #fbeb5b;
  --LimeGreen: #4be133;
  --OldRose: #394a6d;
  --Magnolia: #f4f3fe;
  --ChineseSilver: #cccccc;
  --LavenderGray: #c5c0db;
  --Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
  --blue: rgb(26, 57, 87);
  --white: rgb(236, 236, 236);
  --DeepGreen-CyanTurquoise: #206d62;
  --Satoshi: "Satoshi", sans-serif;
  --DM-Sans: "DM Sans", sans-serif;
  --shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  --overlay: rgba(44, 51, 58, 0.2);
}

.align_txt {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.g-background {
  height: 90vh;
  width: 100vw;
  margin-top: 67px;
  background: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
}

.second_guy {
  height: 90vh;
  width: 100vw;
  background: url("../images/casual2.png") no-repeat;
  background-size: 162px 284px;
  background-position: right;
}

.max405 {
  max-width: 495px;
}

.grid_container {
  margin-top: 20px;
  height: 90vh;
  width: 100vw;
  font-size: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [3];
  grid-template-columns: repeat(3, 1fr);
  -ms-grid-rows: 0 1fr 1fr;
  grid-template-rows: 0 1fr 1fr;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.grid_container .cab {
  margin-right: 15px;
}

.grid_container button {
  margin-top: 0;
  background: transparent;
  border: 1px solid var(--White);
  color: #ffffff !important;
}

.grid_container .grid-3 .ent_d3 {
  background: url("../images/tech_diversity.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: absolute;
  display: none;
  top: 0;
  height: 100%;
}

.grid_container .grid-3 .ent_d3 .trans {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  z-index: 99;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.grid_container .grid-3 .ent_d3 .trans h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 0;
}

.grid_container .grid-3 .ent_d3 .trans p {
  color: var(--White);
}

.grid_container .grid-3 .ent_d3 .trans .c_description {
  position: absolute !important;
  bottom: 0 !important;
}

.grid_container .grid-3:hover .ent_d3 {
  display: block;
}

.grid_container .grid-4 .ent_d4 {
  background: url("https://images.unsplash.com/photo-1542000551557-3fd0ad0eb15f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: absolute;
  display: none;
  top: 0;
  height: 100%;
}

.grid_container .grid-4 .ent_d4 .trans {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  z-index: 99;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.grid_container .grid-4 .ent_d4 .trans h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 0;
}

.grid_container .grid-4 .ent_d4 .trans p {
  color: var(--White);
}

.grid_container .grid-4 .ent_d4 .trans .c_description {
  position: absolute !important;
  bottom: 0 !important;
}

.grid_container .grid-4:hover .ent_d4 {
  display: block;
}

.grid_container h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 152% */
  text-align: center;
  /* White */
  color: #ffffff;
}

.grid_container .gross-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid_container .per_gird {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  z-index: 100;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.grid_container .individual {
  -ms-grid-column: 1;
  grid-column-start: 1;
  visibility: hidden;
  grid-column-end: 4;
}

.grid_container .ent {
  -ms-grid-column: 2;
  grid-column-start: 2;
  grid-column-end: 4;
}

.grid_container .ent .ent_d2 {
  background: url("../images/enterprise_p.png") no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  display: none;
  top: 0;
  height: 100%;
}

.grid_container .ent .ent_d2 .trans {
  width: 100%;
  position: absolute !important;
  bottom: 0;
  z-index: 99;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.grid_container .ent .ent_d2 .trans h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 0;
}

.grid_container .ent .ent_d2 .trans p {
  color: var(--White);
}

.grid_container .ent .ent_d2 .trans .c_description {
  position: absolute !important;
  bottom: 0 !important;
}

.grid_container .ent:hover .ent_d2 {
  display: block;
}

.grid_container .enterprise {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.grid_container .enterprise img {
  height: 360px;
  width: 107px !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  bottom: 20px;
  left: 10px;
  z-index: 0;
  position: absolute !important;
}

.grid_container .enterprise .prior {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: relative;
}

.grid_container .enterprise .prior::after {
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.2)),
      to(rgba(0, 0, 0, 0.2)));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.grid_container .enterprise .prior .trans {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  z-index: 99;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.grid_container .enterprise .prior .trans h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 0;
}

.grid_container .enterprise .prior .trans p {
  color: var(--White);
}

.grid_container .enterprise .prior .trans .c_description {
  position: absolute !important;
  bottom: 0 !important;
}

.grid_container .enterprise .ent_d {
  background: url("../images/individual_p.png") no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: absolute;
  display: none;
  top: 0;
  height: 100%;
}

.grid_container .enterprise:hover .ent_d {
  display: block;
}

.grid_container .footer {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1 / span 3;
  grid-column-start: 1;
  grid-column-end: span 3;
}

.banner {
  margin-top: 130px;
  text-align: center;
  background: url("https://github.com/MicroHub69/Instasew/blob/main/src/images/banner_background.png?raw=true");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.banner_txt {
  text-align: center;
}

.banner_txt h1 {
  margin: 0 auto;
  max-width: 960px;
  font-size: 40px;
  font-weight: 600;
  line-height: 49px;
}

.banner_txt p {
  max-width: 600px;
  margin: 16px auto;
  text-align: center;
}

.get_started_with {
  background: url("https://github.com/MicroHub69/Instasew/blob/main/src/images/bb_background.png?raw=true");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.get_started_with button {
  background: var(--Main-Gradient);
  color: #fff;
  -webkit-text-fill-color: #fff;
  background-clip: border-box;
  height: auto;
  text-align: center;
  margin: 20px auto 70px auto;
}

.get_started_with img {
  width: 375px;
}

.magic_partners {
  margin: 100px 0 50px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  padding: 40px 10px;
  overflow: hidden;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.magic_partners img {
  width: 172px;
  height: 45px;
  margin-right: 70px;
  -o-object-fit: cover;
  object-fit: cover;
}

.tech_point {
  width: 54px !important;
  height: 64px !important;
}

#discover_container {
  max-width: 1161px;
  margin-left: 0;
}

#discover_container .discover {
  margin-top: 50px;
}

#discover_container .discover h2 {
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  margin-bottom: 15px;
}

#discover_container .support {
  margin-top: 80px;
}

#discover_container .support .p_support {
  margin-bottom: 45px;
}

#discover_container .support .p_support img {
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 10px;
}

#discover_container .support .p_support h3 {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 18px;
}

#discover_container .support .p_support p {
  margin-top: 2px;
}

.Majorheading {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.our_vert {
  background-color: var(--Magnolia) !important;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  padding: 60px 10px;
  border-radius: 5px;
  margin-top: 80px;
}

.our_vert h3 {
  font-size: 28px;
  max-width: 560px;
  font-weight: 600;
  margin: 0 auto 50px;
  text-align: center;
}

.our_vert .all-round {
  max-width: 650px;
  margin: 0 auto 0;
  text-align: center;
}

.our_vert .all-round .p_numbers {
  margin-bottom: 30px;
}

.our_vert .all-round .p_numbers h4 {
  font-weight: 700;
  font-size: 32px;
}

.our_vert .all-round .p_numbers p {
  text-align: center;
}

.about_page {
  margin-top: 130px;
}

.about_page h3 {
  text-align: center;
}

.newsletter_s {
  background: var(--Main-Gradient);
  padding: 80px 20px;
  border-radius: 5px;
  margin-top: 80px;
}

.newsletter_s .subscribe {
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
}

.newsletter_s .subscribe h2 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 0;
  line-height: 39px;
  color: var(--White);
}

.newsletter_s .subscribe p {
  color: var(--White);
  text-align: center;
}

.newsletter_s .subscribe form {
  max-width: 500px;
  margin: 0 auto;
}

.newsletter_s .subscribe form input {
  width: 70%;
  border-radius: 5px 0 0 5px;
}

.newsletter_s .subscribe form button {
  width: 30%;
  padding: 0;
  border-radius: 0px 5px 5px 0px;
  height: 50px;
}

#fog_bg {
  padding-top: 40px;
  background: url("https://github.com/MicroHub69/Instasew/blob/main/src/images/fog_section.png?raw=true");
  background-position: -300px 110px;
  background-repeat: no-repeat;
  background-size: 500px 500px;
  width: 100%;
}

.diversion {
  margin-top: 80px;
}

.diversion .choose .top_txt {
  padding: 40px 10px;
  background-color: var(--White);
  border: 1px solid rgba(44, 51, 58, 0.2);
}

.diversion .choose .top_txt img {
  width: 35px;
  height: 35px;
  margin-bottom: 20px;
}

.diversion .choose .top_txt h5 {
  margin-bottom: 0px;
}

.diversion .choose .top_txt p {
  margin-top: 5px;
}

.native-btn {
  background: var(--Main-Gradient);
  color: #ffffff;
  height: auto;
}

.save_to_coll {
  margin-top: 40px;
  position: relative;
}

.save_to_coll>div {
  position: absolute;
  right: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.save_to_coll button {
  width: 100%;
  margin-top: 10px;
}

.about_desc {
  max-width: 1077px;
  margin: 100px auto;
}

.about_desc .ps {
  margin-bottom: 30px;
}

.about_desc .ps span {
  font-weight: 600;
}

.our_stars .heading_p {
  max-width: 600px;
}

.the_stars {
  text-align: center;
  margin-top: 40px;
}

.the_stars .p_star {
  margin: 40px 0;
}

.the_stars .p_star h4 {
  margin-bottom: 0px;
  font-weight: 500;
}

.the_stars .p_star p {
  margin-top: 3px;
}

.the_stars .p_star button {
  width: 160px;
  height: 160px;
  padding: 0;
  font-size: 60px;
  border-radius: 50% !important;
}

.the_stars .p_star button::before {
  border: 0;
  border-radius: 50%;
}

.the_stars img {
  width: 160px;
  height: 160px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.the_stars p {
  text-align: center;
}

.mini_flex {
  margin-top: 130px;
}

.mini_flex h2 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.mini_flex h2 span {
  background: var(--Main-Gradient);
  font-family: var(--DM-Sans);
  font-weight: 600;
  /* Mask the color to the text, and remove the rest  */
  -webkit-background-clip: text;
  font-size: 32px;
  /* Make the text fill color value transparent so the masked background color comes through */
  -webkit-text-fill-color: transparent;
}

.mini_flex img {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mini_flex .thanks {
  margin-bottom: 30px;
}

.mini_flex .the_form {
  border-radius: 5px;
  border: 1px solid rgba(44, 51, 58, 0.2);
  padding: 20px;
}

.mini_flex .the_form input {
  margin-top: 20px;
  border: 1px solid rgba(44, 51, 58, 0.5);
  border-radius: 5px;
}

.mini_flex .the_form button {
  width: 100%;
}

.mini_flex .the_form a {
  font-weight: 600;
}

textarea {
  min-height: 162px;
  width: 100%;
  max-width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid rgba(44, 51, 58, 0.5);
  border-radius: 5px;
  outline: none;
}

.one_input input {
  margin-top: 20px;
  border: 1px solid var(--LightBlack);
  border-radius: 5px;
}

.one_input textarea {
  min-height: 162px;
  width: 100%;
  max-width: 100%;
  padding: 15px;
  margin-top: 20px;
  font-size: 17px;
  border: 1px solid var(--LightBlack);
  border-radius: 5px;
  outline: none;
}

.Blog {
  margin-top: 130px;
}

.Blog .sample_pics {
  margin-bottom: 30px;
}

.blog_head {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 35px;
}

#gallery_cells {
  padding-top: 100px;
}

.upload_image_form {
  position: relative;
  margin-top: 60px;
}

.upload_image_form input {
  display: none;
}

.upload_image_form label {
  position: absolute;
  right: 0;
  padding: 15px 25px;
  font-weight: 600;
  max-width: unset;
}

.card_flex .each_card {
  margin-bottom: 24px;
}

.card_flex .per_photo {
  margin: 10px 0;
}

.card_flex .per_photo img {
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

.card_flex .blog_post {
  border-radius: 5px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
}

.card_flex .blog_post:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.card_flex .blog_post img {
  height: 185px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.card_flex .blog_post .blog_desc {
  padding: 20px;
}

.card_flex .blog_post .blog_desc h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
  overflow: hidden;
  line-height: 25px;
  max-height: 50px;
}

.card_flex .blog_post .blog_desc p {
  margin-bottom: 20px;
  overflow: hidden;
  max-height: 57px;
}

.drag_drop {
  width: 100%;
  height: 180px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1.6px dashed var(--LightBlack);
}

.drag_drop input {
  display: none;
}

.drag_drop label {
  width: 100%;
  font-size: 20px;
  padding: 0 20px;
  cursor: pointer;
  color: var(--LightBlack);
}

.drag_drop label b {
  font-weight: 500 !important;
}

.apply_with {
  border-bottom: 2px solid var(--Magnolia);
}

.apply_with button {
  border: 1px solid var(--Gunmetal);
  height: auto;
  font-size: 14px;
  font-weight: 400;
  color: var(--LightBlack);
}

.add_new_member {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
  display: none;
  position: fixed;
  overflow: scroll;
  background: rgba(44, 51, 58, 0.5);
}

.add_new_member .member_form {
  margin: 100px auto;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  max-width: 840px;
  padding: 50px 20px 100px;
  background-color: var(--White);
}

.add_new_member .member_form .easy_apply {
  position: relative;
}

.add_new_member .member_form .easy_apply h5 {
  margin-bottom: 0;
}

.add_new_member .member_form .easy_apply p {
  margin-top: 4px;
  margin-bottom: 30px;
}

.add_new_member .member_form .easy_apply .close_btn {
  width: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
  top: -5px;
  right: 0;
}

.show_overlay {
  display: block;
}

.lapping_form input {
  margin-top: 20px;
  border: 1px solid var(--LightBlack);
}

.personal_info {
  margin-top: 80px;
  border-bottom: 2px solid var(--Magnolia);
  padding-bottom: 30px;
}

.personal_info img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.experience {
  margin-top: 40px;
  border-bottom: 2px solid var(--Magnolia);
  padding-bottom: 70px;
}

.experience .checkbox {
  margin-top: 20px;
}

.experience .experience_head {
  position: relative;
}

.experience .experience_head .add_experience {
  width: 100%;
  font-family: var(--DM-Sans);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.experience .experience_form img {
  width: 80px;
  height: 80px;
}

.hire_boss h5 {
  margin-top: 50px;
}

.hire_boss textarea {
  min-height: 162px;
  width: 100%;
  max-width: 100%;
  padding: 15px;
  font-size: 17px;
  border: 1px solid var(--LightBlack);
  border-radius: 5px;
  outline: none;
}

.outline_btn {
  border: 1px solid var(--LightBlack);
  padding: 15px 20px;
  text-align: center;
  font-family: var(--DM-Sans);
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
}

.instant_apply {
  position: relative;
  height: 100px;
}

.instant_apply .urgent_ {
  max-width: 440px;
  width: 100%;
  position: absolute;
}

.instant_apply .urgent_ .outline_btn,
.instant_apply .urgent_ button {
  width: 100%;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.db_banner {
  z-index: -1;
  margin-top: 100px;
  background: var(--Main-Gradient);
  position: relative;
  padding: 27px;
  min-height: 250px;
  border-radius: 5px;
}

.db_banner span {
  color: #ffffff;
}

.db_banner h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 10px;
}

.db_banner img {
  width: 220px;
  position: absolute;
  right: 30px;
  bottom: 20px;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}

.db_banner p {
  color: #ffffff;
  max-width: 415px;
}

.statbags {
  margin-top: 80px;
}

.statbags .stat_bag {
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  padding: 17px;
  margin-bottom: 25px;
}

.statbags .bank_log {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
}

.statbags .bank_log img {
  width: 35px;
  height: 35px;
  -o-object-fit: cover;
  object-fit: cover;
}

.statbags .bank_log p {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}

.statbags h3 {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
}

.p_info {
  background: var(--White);
  padding: 20px;
  border: 1px solid rgba(44, 51, 58, 0.2);
  position: relative;
  border-radius: 5px;
  margin-top: 130px;
}

.p_info img {
  margin-right: 40px;
  width: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 15px;
  height: 100px;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.p_info .f_info {
  max-width: 640px;
  width: 100%;
}

.p_info .f_info .p_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p_info .f_info .p_name h3 {
  margin-bottom: 15px;
  margin-right: 15px;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  color: #2c333a;
}

.p_info .f_info .p_name span {
  margin-top: 0;
  padding: 7px 15px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.015em;
}

.p_info .f_info .p_name span::before {
  content: "";
  width: 7px;
  height: 7px;
  display: inline-block;
  position: relative;
  top: -1px;
  border-radius: 50%;
  margin-right: 7px;
}

.p_info .f_info .p_name .am_online {
  color: #37ae58;
  border: 2px solid #37ae58;
}

.p_info .f_info .p_name .am_online::before {
  background-color: #37ae58;
}

.p_info .f_info .p_name .am_offline {
  color: red;
  border: 2px solid red;
}

.p_info .f_info .p_name .am_offline::before {
  background-color: red;
}

.p_info .f_info .p_location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p_info .f_info .p_location .am_in {
  margin-right: 30px;
  margin-bottom: 15px;
}

.each_catelogue {
  border-radius: 5px;
  -webkit-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.each_catelogue::after {
  content: "";
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.2)),
      to(rgba(0, 0, 0, 0.2)));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.each_catelogue img {
  height: 100%;
  border-radius: 5px;
  position: relative;
  z-index: -1;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.each_catelogue:hover {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}

.each_catelogue .catelogue_desc {
  padding: 22px;
  z-index: 20;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0 5px 5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.094);
  -webkit-box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  box-shadow: inset 12.8333px -12.8333px 12.8333px rgba(165, 165, 165, 0.094),
    inset -12.8333px 12.8333px 12.8333px rgba(255, 255, 255, 0.094);
  -webkit-backdrop-filter: blur(70px);
  backdrop-filter: blur(70px);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.each_catelogue .catelogue_desc h3 {
  font-weight: 700;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 5px;
  margin-left: 0;
  text-align: left;
  color: #eeeeee;
}

.each_catelogue .catelogue_desc p,
.each_catelogue .catelogue_desc span {
  font-weight: 400;
  margin-left: 0;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #eeeeee;
}

.create_new {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--Magnolia);
  height: 100%;
}

.create_new input {
  display: none;
}

.create_new .plus {
  --b: 4px;
  /* the thickness */
  width: 40px;
  aspect-ratio: 1;
  border: 10px solid #000;
  cursor: pointer;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background: conic-gradient(from 90deg at var(--b) var(--b),
      #000 90deg,
      #fff 0) calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b)) calc(50% + var(--b));
  border-radius: 50%;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  display: inline-block;
}

.create_new .plus:hover {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}

.main_comment {
  margin-top: 100px;
  padding: 20px;
  background-color: var(--Magnolia);
  border-radius: 5px;
  width: 100%;
}

.main_comment .buyers_comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.main_comment .buyers_comment h5 {
  margin-right: auto;
}

.main_comment .p_comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(44, 51, 58, 0.2);
}

.main_comment .p_comment h5 {
  margin-bottom: 5px;
}

.main_comment .p_comment img {
  width: 55px;
  margin-right: 20px;
  height: 55px;
  border-radius: 50%;
}

.main_comment .p_comment span {
  font-family: var(--DM-Sans);
  font-style: italic;
  margin-top: 15px;
}

#add_to_collection {
  max-width: 650px !important;
}

#create_collection input {
  margin: 20px 0;
}

#create_collection select {
  width: 100% !important;
  height: 45px;
  padding: 15px;
  outline: 0;
}

#create_collection button {
  width: 100%;
}

.socials {
  max-width: 150px;
}

.socials img {
  margin-right: 15px;
  width: 35px;
  border-radius: none;
  height: 35px;
}

.featured_img {
  margin-top: 130px;
}

.featured_img img {
  height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 25px;
}

.featured_img span {
  padding: 5px 20px;
  border-radius: 5px;
  font-weight: 600;
  background-color: var(--Magnolia);
}

.featured_img h4 {
  margin-top: 20px;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #2c333a;
}

.featured_img p {
  max-width: 945px;
}

.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.job_app {
  -webkit-transform: scale(1);
  transform: scale(1);
  background: #ffffff;
  border: 1px solid rgba(44, 51, 58, 0.2);
  -webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
    0px 2px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 24px;
  padding: 24px;
  min-height: 330px;
  height: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.job_app h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
}

.job_app p {
  font-size: 16px;
}

.job_app img {
  display: none;
}

.job_app .posted_by span {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: 0.015em;
}

.job_app .category_by {
  margin-top: 10px;
  max-width: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.job_app .category_by span {
  padding: 5px 30px;
  margin-right: 20px;
  background-color: var(--Magnolia);
  border-radius: 5px;
  font-weight: 600;
}

.job_app:hover {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.job_app .location_applicant {
  margin-top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 280px;
}

.dummy_height {
  height: 70px;
}

.dummy_height button {
  padding-left: 10px;
  min-width: 130px;
}

.tab {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  background: transparent;
  color: var(--Gunmetal);
  border: none;
  padding: 14px 40px;
  margin-top: 5px !important;
  margin-right: 30px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.tab:hover {
  background-color: #f4f4f4;
}

.tab.active {
  color: #ffffff;
  background: var(--DarkCyan);
  font-weight: 500;
}

.panel {
  margin-top: 30px;
  display: none;
}

.panel.active {
  display: block;
}

.b-image {
  margin-top: 80px;
}

.b-image img {
  height: 3wr 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog_title {
  margin-top: 60px;
}

.blog_title .blog_author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 400px;
  width: 100%;
}

.blog_title .blog_author .author img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 20px;
}

.blog_title .blog_author .author span {
  margin-right: 10px;
  top: -20px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.blog_title .blog_author .author span a {
  text-decoration: underline;
}

.blog_title .blog_author .posted_date {
  margin-top: 13px;
}

.blog_title h1 {
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  /* or 131% */
  max-width: 830px;
  margin-top: 15px;
  letter-spacing: 0.04em;
}

.span_tags {
  margin-top: 30px;
}

.label_tags {
  padding: 7px 15px;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  margin-right: 15px;
  background-color: var(--Magnolia);
}

.next_post {
  position: relative;
  margin-top: 20px;
  margin-bottom: 80px;
  margin-right: 20px;
}

.next_post .press_next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 0;
}

.next_post button {
  margin-left: 20px;
}

.comment_section {
  padding-top: 60px;
}

.small_profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 25px;
}

.ff_comment {
  max-width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  padding-top: 30px;
  border-bottom: 2px solid var(--Magnolia);
}

.ff_comment h5 {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.025em;
}

.ff_comment .reaction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ff_comment .reaction li {
  font-weight: 600;
  margin-right: 20px;
  font-size: 14px;
  cursor: pointer;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.the_profile_pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-top: 15px;
}

.on_the_job span {
  font-weight: 700;
}

.load_more {
  margin: 50px 0;
  max-width: 600px;
}

.add_comment button {
  margin-left: 70px;
}

.grip {
  margin-top: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 600px;
}

.grip textarea {
  margin: 0;
  border: 1px solid rgba(44, 51, 58, 0.2);
}

#jobs_banner {
  background: var(--Magnolia) !important;
}

#jobs_banner h2 {
  color: var(--Gunmetal);
  max-width: 640px;
}

#JobCategories>div {
  margin: 10px 0;
}

.time_stamp {
  margin-top: 20px;
}

#Jdescription {
  margin-top: 120px;
}

#Jdescription>div {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  padding: 0;
  -webkit-transform: none;
  transform: none;
}

#Jdescription .job_app {
  padding-bottom: 40px;
  border-bottom: 2px solid var(--Magnolia);
}

#Jdescription .job_app:hover {
  -webkit-transform: none !important;
  transform: none !important;
}

#Jdescription h2 {
  font-size: 26px;
  max-width: 750px;
  line-height: 35px;
}

#Jdescription .location_applicant {
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
}

#Jdescription .location_applicant p {
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  /* identical to box height, or 142% */
  letter-spacing: 0.01em;
}

.project_overview {
  padding: 20px;
  border-radius: 5px;
  border: 2px solid var(--Magnolia);
}

#settings button {
  margin-top: 10px;
  margin-right: 13px;
}

.logOut {
  margin-top: 50px !important;
  color: #ea5f5e;
}

.acct_settings {
  background: var(--White);
  padding: 24px;
  border: 1px solid rgba(44, 51, 58, 0.2);
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  border-radius: 5px;
}

.inner_dic {
  max-width: 500px;
}

.inner_dic .pic_change {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inner_dic .pic_change img {
  width: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  height: 100px;
}

.inner_dic .pic_change label {
  margin-left: 30px;
  border: 1px solid var(--Gunmetal);
  height: 45px;
  color: var(--Gunmetal);
  border-radius: 5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 15px;
  margin-top: 20px;
  font-weight: 500;
}

.inner_dic .pic_change input {
  display: none;
}

.inner_dic form {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(44, 51, 58, 0.2);
}

.inner_dic input {
  margin-top: 20px;
}

.deleting {
  max-width: 500px;
  margin-top: 40px;
}

.deleting button {
  background: none;
  color: #ea5f5e;
  border: 1px solid #ea5f5e;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4;
  transition: 0.4;
}

.deleting button:hover {
  background: #ea5f5e;
  color: #ffffff;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

.primary_q {
  margin-top: 10px;
}

.primary_q input {
  margin-top: 20px;
}

.primary_q select {
  width: 100%;
  height: 45px;
  margin-top: 20px;
}

.primary_q .selected_g>div {
  height: 40px !important;
}

.f_before::before {
  content: url("https://www.svgrepo.com/show/132839/facebook.svg");
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 8px;
}

.rec_banner {
  border-bottom: 1px solid rgba(44, 51, 58, 0.2);
  padding-bottom: 30px;
}

.change_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.change_flex .sec_alert {
  max-width: 700px;
  width: 87%;
}

.set_password input {
  margin-top: 20px;
  max-width: 450px;
}

.connected h4 {
  margin-bottom: 20px;
}

.all_devices .bdt {
  border-top: 1px solid var(--LightBlack);
}

.all_devices .desktop {
  position: relative;
}

.all_devices .desktop::before {
  content: url("https://www.svgrepo.com/show/408842/desktop.svg");
  left: -40px;
  width: 16px;
  top: 10px;
  height: 16px;
  position: absolute;
  display: block;
  margin: 0 5px 0 0;
}

.all_devices .phones {
  position: relative;
}

.all_devices .phones::before {
  content: url("https://www.svgrepo.com/show/357148/iphone.svg");
  left: -40px;
  width: 30px;
  top: 10px;
  height: 30px;
  position: absolute;
  display: block;
  margin: 0 5px 0 0;
}

.d_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 40px;
}

.d_wrap .devices h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 5px;
}

.d_wrap .devices span {
  font-weight: 600;
}

.d_wrap img {
  margin-top: 10px;
  width: 35px;
  cursor: pointer;
  height: 35px;
}

.txt-notification {
  max-width: 450px;
}

.box {
  border-radius: 15px;
  height: 51px;
  width: 100%;
  max-width: 355px;
}

.box label {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.box label span {
  border: 1px solid var(--Gunmetal);
  margin: 0;
  position: relative;
  color: var(--Gunmetal);
  display: inline-block;
  font-weight: 500;
  padding: 10px 15px;
  padding-left: 25px;
  width: 33.2%;
  height: 51px;
  background: var(--White);
}

.box label input {
  display: none;
  opacity: 0;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.box label input:checked~span {
  color: var(--White);
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  font-weight: 600;
  background: var(--Main-Gradient);
}

.p_bells {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p_bells h5 {
  font-weight: 600;
  margin-top: 15px;
  font-size: 16px;
  margin-right: 10px;
}

.heading {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 130px;
}

.heading h3 {
  margin-bottom: 15px;
  font-weight: 600;
}

.heading p {
  text-align: center;
}

.plans {
  border: 1px solid rgba(44, 51, 58, 0.2);
  border-radius: 5px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 30px;
}

.plans:hover {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
}

.subsc {
  margin-top: 50px;
  margin-bottom: 100px;
}

.subsc .plan_top {
  text-align: center;
  background-color: var(--Magnolia);
}

.subsc .plan_top h3 {
  background-color: var(--Gunmetal);
  padding: 5px 0;
  color: var(--White);
  font-size: 18px;
  margin: 0;
}

.subsc .plan_top h4 {
  padding-top: 15px;
  font-weight: 600;
}

.subsc .plan_top p {
  padding-bottom: 15px;
  margin-top: 0px;
  text-align: center;
  font-weight: 500;
}

.subsc .access {
  padding: 10px 20px;
}

.subsc .access button {
  margin-top: 100px;
  width: 100%;
}

.subsc .access li {
  position: relative;
  margin-left: 45px;
  margin-top: 20px;
}

.subsc .access li::before {
  background: url("https://www.svgrepo.com/show/49904/check-mark-black-outline.svg");
  background-repeat: no-repeat;
  content: "";
  background-size: cover;
  display: block;
  height: 20px;
  position: absolute;
  top: 6px;
  left: -40px;
  width: 20px;
}

.subsc .access .not_available {
  position: relative;
  margin-left: 45px;
}

.subsc .access .not_available::before {
  background: url("https://www.svgrepo.com/show/112623/close.svg");
  background-repeat: no-repeat;
  content: "";
  background-size: cover;
  display: block;
  height: 20px;
  position: absolute;
  top: 6px;
  left: -40px;
  width: 20px;
}

.plan_b {
  margin-top: 120px;
  background: var(--Magnolia);
  border: 1px solid rgba(44, 51, 58, 0.1);
  border-radius: 5px;
  padding: 24px;
}

.plan_b .summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.plan_b .summary h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.plan_b .summary button {
  padding: 15px 20px;
}

.plan_b p {
  max-width: 600px;
}

.select_case {
  max-width: 700px;
}

.select_case input {
  margin-top: 20px;
}

.select_case select {
  background-position-x: 400px;
  width: 100%;
  max-width: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  outline: none;
  color: var();
  font-family: var(--DM-Sans);
  border: 1px solid #d0d0d0;
}

.select_case select option {
  padding: 15px;
}

.transformation {
  max-width: 990px;
  margin-left: 0;
}

.transformation::-webkit-input-placeholder {
  font-size: 20px;
}

.transformation:-ms-input-placeholder {
  font-size: 20px;
}

.transformation::-ms-input-placeholder {
  font-size: 20px;
}

.transformation::placeholder {
  font-size: 20px;
}

.transformation textarea {
  margin-top: 0px;
}

.billing_info {
  margin-top: 50px;
}

.billing_info h2 {
  margin-bottom: 5px;
  font-size: 25px;
}

.billing_info input {
  margin-top: 20px;
}

.method_pay {
  margin-top: 50px;
}

.method_pay h2 {
  font-size: 25px;
  margin-bottom: 5px;
}

.cct {
  display: none;
}

#radio-1:checked~#ff_test-1 {
  display: block;
}

.all_payment .tab.active {
  background: none;
  border: 1px solid var(--LimeGreen);
}

.all_payment .radio_parent {
  width: 100%;
}

.all_payment h3 {
  background-color: var(--Corn);
}

#lines_b {
  padding: 0px 20px;
  padding-top: 3px;
  height: 50px;
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  border: 2px solid var(--Magnolia);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#lines_b img {
  width: 130px;
  height: 26px;
}

#lines_b input+span {
  margin: 0;
}

.btn-position {
  position: relative;
  height: 10px;
}

.btn-position button {
  position: absolute;
  margin-bottom: 30px;
  right: 0;
}

.b_heading {
  margin-top: 110px;
}

.client_section {
  margin-top: 20px;
}

.per_creators {
  background-color: var(--White);
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  border-radius: 5px;
  margin-bottom: 20px;
}

.client_section .post_pic {
  height: 150px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.client_section .wrap_desc {
  padding: 15px;
}

.client_section .wrap_desc .about_project {
  border-bottom: 2px solid var(--Magnolia);
}

.client_section .wrap_desc .about_project h5 {
  margin-bottom: 10px;
  margin-top: 15px;
}

.client_section .wrap_desc .about_project h5::before {
  content: url("https://www.svgrepo.com/show/285189/stars-star.svg");
  width: 35px;
  height: 35px;
  margin-right: 10px;
  position: relative;
  top: 8px;
  display: inline-block;
}

.client_section .wrap_desc .howmuch {
  position: relative;
  height: 35px;
  margin-top: 5px;
}

.client_section .wrap_desc .howmuch h4 {
  position: absolute;
  right: 0;
  margin-bottom: 0;
}

.client_section .profile {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}

.client_section .job_bider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.client_section .job_bider img {
  width: 32px;
  height: 32px;
}

.client_section .job_bider .grace {
  margin-right: auto;
}

.client_section .job_bider h2 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  margin-right: auto;
  line-height: 22px;
}

.client_section .job_bider p {
  margin-top: 0px;
  color: var(--LightBlack);
}

.manage_request {
  margin-top: 100px;
}

.manage_request h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
}

#activetable {
  margin-top: 30px;
}

#activetable button {
  margin: 0;
  padding: 10px 15px;
  border-radius: 0;
  background-color: transparent;
}

#activetable .tab.active {
  color: #ffffff;
  border-bottom: 3px solid var(--Main-Gradient);
}

.overflow-table {
  overflow-x: scroll;
}

.overflow-table table {
  max-width: 1100px !important;
  width: 1050px;
}

.overflow-table table th,
.overflow-table table tr td:nth-child(2) {
  max-width: 200px;
}

.overflow-table table tr td:nth-child(4) {
  max-width: 170px;
}

.overflow-table table a {
  padding-top: 20px;
  text-decoration: underline;
}

.overflow-table table a:hover {
  text-decoration: none;
}

.overflow-table .delete_pause {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.overflow-table .delete_pause button {
  margin: 0;
  height: 45px;
}

.job_p {
  margin-top: 100px;
}

.job_p .breadcrumb {
  margin-bottom: 30px;
}

.job_p h4 {
  margin-top: 0;
}

.my_job_post {
  background-color: var(--Magnolia);
  padding: 20px;
  margin-top: 30px;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  background: rgba(244, 244, 244, 0.9);
  border: 1px solid rgba(44, 51, 58, 0.2);
  border-radius: 5px;
}

.my_job_post h3 {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.autocomplete-items {
  z-index: 99;
  position: absolute;
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  background-color: var(--White);
  top: 50px;
}

.autocomplete-items .autocomplete-items div {
  padding: 10px;
  font-family: var(--Satoshi);
  cursor: pointer;
  z-index: 99;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items .autocomplete-items div:hover {
  background-color: var(--Magnolia);
}

.dts_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 470px;
}

.dts_info p {
  margin-bottom: 0;
}

.control_Grid {
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 80px;
}

.control_Grid .already {
  position: relative;
  height: 45px;
  margin-top: 30px;
}

.control_Grid .already h5 {
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
}

.control_Grid .already h5 span {
  font-weight: 600;
}

.illustrate {
  background-color: var(--Magnolia);
  padding: 50px;
  padding-left: 80px;
  width: 35%;
  height: 590px;
}

.illustrate h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 10px;
}

.illustrate img {
  width: 230px;
  height: 230px;
  margin-top: 20px;
}

.progressbar {
  height: 5px;
  border-radius: 50px;
  width: 100%;
  background-color: var(--Magnolia);
  position: absolute;
  top: 0;
  left: 0;
}

.progressbar>div {
  width: 0%;
  height: 100%;
  border-radius: 50px;
  background: var(--Brand-color);
}

.get_started_form {
  max-width: 500px;
  padding: 20px;
  position: relative;
  margin-top: 30px;
}

.get_started_form button {
  border: 1px solid var(--Main-Gradient);
  padding: 10px 15px;
  margin-right: 20px;
}

.get_started_form .or {
  text-align: center;
}

.get_started_form .or::before {
  content: "";
  position: relative;
  top: -2px;
  width: 40%;
  margin-right: 10px;
  height: 1px;
  background-color: var(--Gunmetal);
  display: inline-block;
}

.get_started_form .or::after {
  content: "";
  width: 40%;
  margin-left: 10px;
  height: 1px;
  background-color: var(--Gunmetal);
  display: inline-block;
}

.get_started_form .l_button button {
  width: 100%;
  padding: 12px;
}

.get_started_form input {
  margin-top: 20px;
}

.get_started_form .radio_parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.get_started_form .radio_parent span {
  margin-right: 20px;
}

.get_started_form .city_country {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.get_started_form .city_country input {
  width: 48%;
}

.get_started_form .header p {
  margin-bottom: 10px;
}

.get_started_form h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 10px;
}

.get_started_form p {
  margin-bottom: 20px;
}

.get_started_form .acct_cat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 400px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.get_started_form .acct_cat>div {
  text-align: center;
  background-color: var(--White);
  cursor: pointer;
  border: 2px solid rgba(44, 51, 58, 0.2);
  -webkit-filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.05));
  border-radius: 5px;
  padding: 40px;
  width: 180px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin: 0 auto;
  margin-bottom: 30px;
}

.get_started_form .acct_cat>div:hover {
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}

.get_started_form .acct_cat img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.alternate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.alternate h5 {
  width: 48%;
  margin: 0;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  height: 50px;
  padding-top: 6px;
  border: 1px solid var(--Gunmetal);
  font-weight: 400px;
}

.alternate .google {
  position: relative;
}

.alternate .google:before {
  content: url(https://www.svgrepo.com/show/355037/google.svg);
  margin-right: 8px;
  width: 15px;
  position: relative;
  top: 5px;
  height: 15px;
}

.alternate .facebook {
  position: relative;
}

.alternate .facebook:before {
  content: url(https://www.svgrepo.com/show/138943/facebook.svg);
  margin-right: 8px;
  width: 25px;
  display: inline-block;
  position: relative;
  top: 5px;
  height: 25px;
}

#bca {
  background: url(https://media.istockphoto.com/photos/modern-keyboard-wih-blue-log-in-button-picture-id638149354?k=20&m=638149354&s=612x612&w=0&h=bkTvrKEv4ENMymYn-3FLMDBR9BQc3ZqVEO_rrDAm4GQ=) no-repeat;
  background-position: center;
  background-size: cover;
  height: 590px;
}

#bca img {
  display: none;
}

.manage_swi {
  display: none;
}

.interested {
  margin-top: 120px;
  margin-bottom: 50px;
  min-height: 100vh;
  position: relative;
  height: 100%;
}

.interested h1 {
  font-size: 30px;
  font-weight: 500;
}

.interested input {
  width: auto;
}

.interested .choose_int {
  max-width: 1000px;
}

.interested ul.ks-cboxtags {
  list-style: none;
  padding: 20px 20px 20px 0;
}

.interested ul.ks-cboxtags li {
  display: inline;
}

.interested ul.ks-cboxtags li label {
  display: inline-block;
  border: 2px solid var(--Gunmetal);
  color: var(--Gunmetal);
  border-radius: 5px;
  font-weight: 500;
  white-space: nowrap;
  margin: 10px 15px 10px 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.interested ul.ks-cboxtags li label {
  padding: 8px 12px;
  cursor: pointer;
}

.interested ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 16px;
  padding: 2px 6px 2px 2px;
  content: "-";
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.interested ul.ks-cboxtags li input[type="checkbox"]:checked+label::before {
  content: "+";
  font-size: 16px;
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.interested ul.ks-cboxtags li input[type="checkbox"]:checked+label {
  border: 2px solid var(--Main-Gradient);
  background: var(--Main-Gradient);
  color: #fff;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.interested ul.ks-cboxtags li input[type="checkbox"] {
  display: absolute;
}

.interested ul.ks-cboxtags li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.interested ul.ks-cboxtags li input[type="checkbox"]:focus+label {
  border: 2px solid var(--PoliceBlue);
}

.think_int {
  position: absolute;
  bottom: 0;
  right: 0;
}

.think_int img {
  width: 230px;
  height: 311px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog_txt h1 {
  font-size: 30px;
  font-weight: 600;
}

/* Hello */
.slick-dots {
  bottom: -60px !important;
}

.slick-dots li button:before {
  width: 30px !important;
  height: 30px !important;
  color: var(--DarkCyan) !important;
}

.slick-dots li.slick-active button:before {
  width: 30px !important;
  height: 30px !important;
  color: var(--DarkCyan) !important;
}

.testimonial_class {
  margin-top: 60px;
  max-width: 1024px;
  margin: 60px auto 0 auto;
}

.testimonial_class h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 30px;
  text-align: center;
}

.testimonial_class .testimonial_carousels .overflow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonial_class .testimonial_carousels .overflow img {
  margin-right: 15px;
  margin-bottom: 0 !important;
}

.testimonial_class .testimonial_carousels .per_review {
  background: var(--White) !important;
  margin: 8px !important;
  padding: 20px;
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  border-radius: 5px;
}

.testimonial_class .testimonial_carousels .per_review img {
  width: 40px;
  border-radius: 50%;
  border: 1px solid var(--DarkCyan);
  height: 40px;
  margin-bottom: 20px;
}

.testimonial_class .testimonial_carousels .per_review p {
  margin-bottom: 20px;
}

.testimonial_class .testimonial_carousels .per_review h3 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.testimonial_class .testimonial_carousels .per_review h6 {
  font-size: 16px;
  font-weight: 400;
  color: #686868;
}

.contract .slick-dots {
  bottom: 0 !important;
}

#Advert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#Advert .ad_title {
  padding: 10px;
  width: 45%;
}

#Advert .ad_title h2 {
  margin-bottom: 10px;
  text-align: left;
}

#Advert .ad_img img {
  width: 300px !important;
  height: auto;
}

#request_p {
  margin-top: 70px;
}

#request_p .display {
  height: 150px;
  width: 100%;
  border-radius: 1px;
  -o-object-fit: cover;
  object-fit: cover;
}

#request_p img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 15px;
  border: 1px solid var(--ChineseSilver);
}

#request_p .hero_b {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#request_p .hero_b button {
  display: inline-block;
  padding: 0 !important;
  border: 0;
  height: 45px;
  margin: 0;
  margin-top: 25px !important;
  margin-bottom: 20px !important;
  width: 47.5%;
}

#request_p .people {
  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  border-radius: 5px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 30px;
}

#request_p .people:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

#request_p .people .name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#request_p .people .d_container {
  padding: 15px;
}

#request_p .people .wrap_price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#request_p .people .wrap_price h1 {
  font-size: 19px;
  font-weight: 600;
  margin-top: 12px;
  margin-right: 10px;
  line-height: 22px;
}

#request_p .people .wrap_price p {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}

.id_online {
  -webkit-animation: heart 1s infinite ease-in-out;
  animation: heart 1s infinite ease-in-out;
  background-color: green;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  margin-top: 17px;
}

.id_offline {
  background-color: yellow;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  margin-top: 17px;
}

.mid {
  background-color: white;
  height: 50px;
  width: 50px;
}

@-webkit-keyframes heart {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0.5;
    transform: scale(1);
  }
}

@keyframes heart {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0.5;
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation slide-bottom
 * ---------------------------------- */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

@media (max-width: 540px) {
  .banner_txt h1 {
    font-size: 28px;
    line-height: 40px;
  }

  #request_p .people .wrap_price h1 {
    margin-top: 0;
  }

  #Advert .ad_img img {
    width: 145px !important;
  }

  .b-image img {
    height: 250px;
  }

  .phone_top {
    margin-top: 90px;
  }

  .wrap_moon {
    display: none;
  }

  .think_int {
    display: none;
  }

  .get_started_form .acct_cat {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    margin: 30px auto;
  }

  .db_banner {
    min-height: 390px;
  }

  .db_banner img {
    width: 160px;
    height: 140px;
  }

  .alternate button {
    width: 100%;
  }

  .b-image img {
    height: 250px;
  }

  .efcc {
    padding-left: 30px;
  }

  .group_pic {
    border-radius: 5px;
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  #settings button {
    padding: 10px 10px;
  }
}

@media (max-width: 480px) {
  .db_banner {
    min-height: 475px;
  }
}

@media (min-width: 540px) {
  .our_vert .all-round {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .our_vert .p_numbers {
    width: 50%;
  }

  .card_on_blog .card_flex {
    width: 100%;
  }

  .save_to_coll {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
  }

  .save_to_coll>div {
    position: absolute;
    max-width: 300px;
    right: 0;
    top: -60px;
  }

  .save_to_coll .native-btn {
    width: 100%;
  }

  .save_to_coll button {
    width: 47%;
    height: 45px;
    margin-left: 0;
  }

  .statbags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .statbags .stat_bag {
    -webkit-box-shadow: var(--shadow);
    box-shadow: var(--shadow);
    padding: 17px;
    width: 48%;
  }

  .card_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .card_flex .each_card {
    width: 49%;
  }

  .mini_flex_for_save {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .mini_flex_for_save h2 {
    width: 87%;
    margin-right: auto;
  }

  .mini_flex_for_save img {
    display: block;
    width: 35px;
    height: 35px;
  }

  .lapping_form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .lapping_form input {
    width: 48%;
  }

  .add_new_member .member_form {
    padding: 50px 40px 100px;
  }

  .the_stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 1066px;
    margin: 0 auto;
  }

  .the_stars .p_star {
    max-width: 250px;
    width: 100%;
  }

  .apply_with {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .apply_with .drag_drop {
    width: 65%;
  }

  .apply_with .double_btn {
    width: 35%;
    padding: 0 20px;
  }

  .experience_head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .experience_head .add_experience {
    margin: 0;
    width: auto !important;
    max-width: 200px;
  }

  .experience .experience_form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .experience .experience_form .inner_exp {
    width: 85%;
    background: var(--Magnolia);
    padding: 20px;
    border: 1px solid rgba(44, 51, 58, 0.2);
    border-radius: 5px;
  }

  .instant_apply {
    position: relative;
    height: 100px;
  }

  .instant_apply .urgent_ {
    max-width: 400px;
    position: absolute;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .instant_apply .urgent_ .outline_btn,
  .instant_apply .urgent_ button {
    height: 50px;
    width: 47% !important;
  }

  .instant_apply .urgent_ .outline_btn {
    margin-top: 30px;
    cursor: pointer;
  }
}

@media (min-width: 940px) {
  .card_flex .each_card {
    width: 32.5%;
  }
}

@media (min-width: 1280px) {
  .card_flex .each_card {
    width: 24%;
  }
}

@media (max-width: 1024px) {
  .illustrate {
    display: none;
  }

  .control_Grid .structure2 {
    margin-top: 100px;
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  #ForViewPort {
    display: none;
  }

  #Advert {
    display: block;
  }

  #Advert .ad_title {
    width: 100%;
  }

  .second_guy {
    background-position: bottom right;
    height: 100%;
  }

  .g-background {
    height: 100%;
  }

  .grid_container {
    display: block;
    height: 100%;
  }

  .grid_container .per_gird {
    min-height: 400px;
  }

  .grid_container .individual {
    display: none;
  }

  .grid_container .enterprise img {
    width: 100px;
    height: 355px;
    margin-top: 30px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  #Advert .ad_img img {
    margin: 0 auto;
    width: 250px !important;
  }
}

@media (min-width: 768px) {
  #discover_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .bbn .benefits {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .bbn .benefits .per_b {
    width: 45%;
  }

  .createdAnother {
    display: none;
  }

  #request_p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  #request_p .people {
    width: 47%;
  }

  .client_section {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 72%;
    min-height: 200px;
    margin-top: 40px;
  }

  .client_section .per_creators {
    width: 48.5%;
  }

  .billing_info .ctfs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 768px;
  }

  .billing_info .ctfs input {
    width: 48.5%;
  }

  .target_c {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .target_c .all_payment {
    width: 45%;
    margin-right: 40px;
  }

  .target_c .input_content {
    width: 40%;
  }

  .target_c .input_content h3 {
    background-color: var(--Corn);
  }

  .subsc {
    margin-top: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  .subsc .plans {
    margin-bottom: 20px;
  }

  .project_overview {
    padding: 30px;
  }

  .workpress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .workpress #accordion_filter {
    width: 25%;
    height: 100%;
  }

  .workpress #job_press {
    margin-top: 37px;
    width: 70%;
  }

  .mini_parent_tool-tip {
    position: static;
  }

  .the_profile_pic {
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .the_profile_pic .add_on_list {
    display: none;
    position: absolute;
    background-color: var(--White);
    right: 72px;
    top: 80px;
    width: 210px;
    padding: 20px;
    border-radius: 8px;
    z-index: 99;
    -webkit-box-shadow: var(--shadow);
    box-shadow: var(--shadow);
  }

  .the_profile_pic .add_on_list li {
    border-bottom: 1px solid var(--Magnolia);
  }

  .the_profile_pic .add_on_list::after {
    content: "";
    background-color: var(--White);
    display: inline-block;
    width: 35px;
    height: 35px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    border-radius: 8px;
    top: -13px;
    right: 15%;
  }

  .the_profile_pic:hover .add_on_list {
    display: block;
  }

  .main_comment {
    padding: 40px;
  }

  .p_info .f_info .p_name span {
    padding: 10px 15px;
  }

  .dummy_height {
    height: 70px;
    margin-top: 20px;
  }

  .dummy_height button {
    min-width: 223px;
    height: 45px;
    text-align: left;
    font-weight: 500;
    padding-left: 15px;
  }

  #CreatorsNav {
    margin-right: 0;
    width: 70%;
  }

  .p_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 40px;
  }

  .p_info .edit_p {
    position: absolute;
    bottom: 30px;
    right: 40px;
  }

  .tab_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .tab_flex .flex_1 {
    width: 30%;
  }

  .tab_flex .flex_2 {
    width: 70%;
  }

  .statbags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .statbags .stat_bag {
    -webkit-box-shadow: var(--shadow);
    box-shadow: var(--shadow);
    padding: 17px;
    width: 32.5%;
  }

  .force_me {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .about_desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .about_desc .ps {
    width: 50%;
    margin: 0;
    padding-right: 30px;
  }

  .about_desc .ps p {
    padding-right: 60px;
  }

  .about_desc .grided {
    width: 50%;
  }

  .Blog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1077px;
    margin-left: 0;
  }

  .Blog .sample_pics {
    width: 45%;
  }

  .Blog .blog_txt {
    width: 50%;
  }

  .Blog .blog_txt button {
    margin-top: 15px;
  }

  .mini_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1077px;
    margin: 130px 0;
  }

  .mini_flex .thanks,
  .mini_flex .the_form {
    width: 45%;
  }

  .diversion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 900px;
    margin: 100px auto;
  }

  .diversion .choose {
    width: 33%;
  }

  .diversion .choose .top_txt {
    padding: 52px 10px;
  }

  .our_vert .p_numbers {
    width: 30%;
  }

  .discover {
    width: 50%;
    padding-right: 30px;
  }

  .discover button {
    margin-left: 0;
  }

  .support {
    margin-top: 60px !important;
    width: 50%;
  }

  .efcc {
    padding-left: 50px;
  }
}

@media (min-width: 1024px) {
  #discover_container .support {
    width: 40%;
  }

  #request_p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  #request_p .people {
    width: 32.5%;
  }

  .control_Grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .control_Grid .structure2 {
    width: 70%;
    padding-left: 20px;
  }

  .efcc {
    padding-left: 60px;
  }
}

@media (min-width: 1024px) {
  .bbn .benefits {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .bbn .benefits .per_b {
    width: 29.5%;
  }
}

/*===============================
PREDEFINED CLASSES FOR KEYFRAMES
================================= */
.bounce-top {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

.scale-in-ver-center {
  -webkit-animation: scale-in-ver-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.bounce-in-right {
  z-index: 1;
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}

.flip-in-ver-right {
  -webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-ver-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.rotate-in-top {
  -webkit-animation: rotate-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-bck-top {
  -webkit-animation: slide-in-bck-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bck-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-bck-center {
  -webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bck-center 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*=========
KEYFRAMES
============ */
/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  24% {
    opacity: 1;
  }

  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
    transform: translateZ(600px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-top {
  0% {
    -webkit-transform: translateZ(700px) translateY(-300px);
    transform: translateZ(700px) translateY(-300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bck-top {
  0% {
    -webkit-transform: translateZ(700px) translateY(-300px);
    transform: translateZ(700px) translateY(-300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-top
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-top {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes rotate-in-top {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
    transform: rotateY(-80deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
    transform: rotateY(-80deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

/**
* ----------------------------------------
* animation scale-in-ver-center
* ----------------------------------------
*/
@-webkit-keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

/**
* ----------------------------------------
* animation heartbeat
* ----------------------------------------
*/
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}